<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="() => handelSave = true" :valid="valid"
            :deleteMethod="deleteMethod" :createBtn="tab == 0" />
         <!-- End Of Header -->

         <!-- Tabs -->
         <v-col cols="12" class="pa-0 mt-3 px-3" v-if="pageData.isEdit">
            <v-tabs color="black" class="rounded-t-xl rounded-b-0  overflow-hidden border_slide"
               active-class="accent--text" v-model="tab">
               <v-tab class="font-weight-bold text-body-2">
                  <strong>{{ $t('Product data') }}</strong>
               </v-tab>
               <v-tab class="font-weight-bold text-body-2">
                  <strong>{{ $t('Photo Gallery') }}</strong>
               </v-tab>
               <v-tab class="font-weight-bold text-body-2">
                  <strong>{{ $t('Category card') }}</strong>
               </v-tab>
               <v-tab class="font-weight-bold text-body-2">
                  <strong>{{ $t('attributes') }}</strong>
               </v-tab>
            </v-tabs>
         </v-col>
         <!-- End Tabs -->

         <!-- ProductData page -->
         <ProductData v-if="tab === 0" :SaveValidation="SaveValidation" :handelSave="handelSave"
            :returnPageData="returnPageData" :resetHandelSave="resetHandelSave" />
         <!--End ProductData page -->

         <!-- Attributes page -->
         <ProductAttributes v-if="tab === 3" :SaveValidation="SaveValidation" />
         <!--End Attributes page -->


      </v-container>

   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import ProductAttributes from "./ProductAttributes/ProductAttributes.vue";
import ProductData from "./ProductData.vue";

export default {
   name: "ProductControl",

   data: () => ({
      pageData: {
         screen_code: "02-004",
         url: null,
         controlRoute: "/code/product-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      handelSave: false,
      tab: 0,
      form: {},
   }),
   components: {
      ControlHeader,
      ProductAttributes,
      ProductData
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   watch: {
      tab() {
         this.valid = false;
         this.handelSave = false;
      }
   },
   computed: {

   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.pageData.editIsLoading = false;
               this.pageData.isLoading = false;

            } else {
               this.$router.push('/')
            }
         }
         else if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
            this.pageData.isLoading = true;
            this.viewForm = true;
            this.pageData.isLoading = false;
         }

      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`product/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
      SaveValidation(valid) {
         return valid ? this.valid = valid : this.valid = false
      },
      returnPageData(data) {
         this.pageData = data;
      },
      resetHandelSave() {
         return this.handelSave = false;
      }
   },
};
</script>

<style lang="scss" scoped>
.border_slide {
   border-bottom: 2px solid #bf9d5f69;
}
</style>