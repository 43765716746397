<template>
  <v-container fluid class="bgAuth d-flex align-center" style="height: 100vh; ">
    <v-container class="pa-0">
      <v-row justify="center" justify-md="start">
        <!-- <v-col cols="12" lg="6" md="5" sm="7" class="pa-0 d-md-block d-none ma-0 bgLog"></v-col> -->
        <v-col cols="11" lg="5" md="7" xs="12" class="pa-0">
          <v-card class="login-card backgroundW pa-5">
            <v-col cols="12" class="py-0">
              <img src="@/assets/img/png/logo.png" class="d-block" alt />
            </v-col>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-col cols="12">
                <h3 class="text-h6 font-weight-black">
                  <span class="gray8--text">
                    {{ $t("welcome") }}
                  </span>
                  {{ $t('login') }}</h3>
              </v-col>
              <v-col cols="12" class="py-0">
                <h5 class="mb-3">{{ $t('username') }}</h5>
                <v-text-field class="my-2" outlined :rules="$global.requiredRule" v-model="username"
                  @keydown.enter="login()" dense color="accent" prepend-inner-icon="mdi-account-check" type="text">
                </v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <h5 class="mb-3">{{ $t("password") }}</h5>
                <v-text-field class="my-2" outlined required color="accent" :rules="passwordRule" v-model="password"
                  @keydown.enter="login()" dense :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="show ? 'text' : 'password'" prepend-inner-icon="mdi-account-key" @click:append="show = !show">
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-row justify="space-between" align="center">
                  <v-col cols="6" class="ma-0 py-0">
                    <!-- <v-checkbox
                      v-model="remember"
                      color="accent"
                      :label="$i18n.t('Remember me')"
                    ></v-checkbox> -->
                    <v-btn plain height="auto" color="primary--text" to="/forget-password">
                      {{ $t("Forget Password") }} {{ $t("?") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" sm="auto">
                    <v-btn @click="login" :loading="loading" :disabled="!valid" class="mi-start-auto shadow"
                      color="accent">{{ $t("login") }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
.bgAuth {
  background-image: url("../../assets/img/png/auth_bg.png");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  // background: #fff;
  .bgLog {
    background-image: url("../../assets/img/png/auth_bg.png");
    background-attachment: inherit;
    background-repeat: no-repeat;
    // background-position: 50%;
    background-size: cover;
    height: 105vh;
  }

  .login-card {
    background: transparent;
    background-image: url("../../assets/img/png/Mask Group 1.png");
    background-attachment: inherit;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 20px;
    border: 0 !important;
    overflow: hidden;
    box-shadow: none !important;

    img {
      max-height: 100px;
    }
  }
}
</style>
<script>
export default {
  name: "LoginPage",
  computed: {},
  watch: {
    companyNumber() {
      this.$store.commit('UPDATE_CLIENT', this.companyNumber || null)
    },
  },
  mounted() {
    this.$api.LOGOUT(false);
    localStorage.setItem('app_version', this.$store.state.app_version)
  },
  components: {},
  data() {
    return {
      show: false,
      valid: false,
      remember: true,
      loading: false,
      connectionStatus: '',
      companyNumber: null,
      username: null,
      password: null,
      usernameRule: [
        v => !!v || this.$t('Username is required')
      ],
      passwordRule: [
        v => !!v || this.$t('Password is required'),
        v => (v && v.length >= 1) || this.$t('Password must be greater than 5 characters'),
      ],
      companyIDRule: [
        v => !!v || this.$i18n.t('Company number is required'),
        v => (v && v.length >= 1) || this.$i18n.t('Company ID not valid'),
      ],
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true && this.$store.state.clientID) {
        const requstBody = {
          username: this.username,
          password: this.password,
        }
        this.$api.LOGIN_AUTH(requstBody).then((response) => {
          this.loading = false;
          if (response.check == true) {
            this.$router.push('/');
            localStorage.setItem("clientID", this.$store.state.clientID)
          }
        })
      }
      else {
        this.loading = false;
      }

    },

  },
};
</script>
