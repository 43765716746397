<template>
  <label for="dropzoneFile" class="dropzone" :class="{ 'active-dropzone': active }"
    :style="`height:${height}%;width:${width}%`" @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive"
    @dragover.prevent @drop.prevent="dropFile" @change="dropFile">
    <div class="d-flex flex-column justify-center align-center" v-if="!showFiles || previewArray.length === 0">
      <span>Drag or Drop File</span>
      <span>OR</span>
      <label for="dropzoneFile">Select File</label>
    </div>
    <input ref="input" type="file" id="dropzoneFile" class="dropzoneFile" name="files" :multiple="multi">

    <div v-show="showFiles">

      <div v-if="multi" class="d-flex flex-wrap justify-end align-center">
        <div v-for="(arr, i) in previewArray" :key="i">
          <iframe v-if="isPdf(arr)" :src="arr" :width="pdfWidth" :height="pdfHight" frameborder="0"></iframe>

          <img v-else-if="!isPdf(arr)" :src="arr" :width="imgWidth" :max-height="imgHight" class="ma-3" />
        </div>
      </div>

      <div v-if="!multi && previewArray.length">
        <iframe v-if="isPdf(lastArrItem)" :src="lastArrItem" :width="pdfWidth" :height="pdfHight"
          frameborder="0"></iframe>

        <v-img v-if="!isPdf(lastArrItem)" :src="lastArrItem" :width="imgWidth" :max-height="imgHight"
          class="ma-3"></v-img>
      </div>

    </div>

  </label>
</template>



<script>

export default {
  name: "DropZone",
  data: () => ({
    active: false,
    files: null,
    previewArray: [],
  }),
  props: {
    multi: { default: false },
    height: { default: 100 },
    width: { default: 100 },
    imgWidth: { default: 200 },
    imgHight: { default: 200 },
    pdfWidth: { default: 200 },
    pdfHight: { default: 200 },
    showFiles: { default: true },
    backDataType: { default: 'normal' },
    backDataMethod: { default: Function },
  },
  methods: {
    toggleActive() {
      this.active = !this.active
    },
    dropFile($event) {
      if ($event.type === 'drop') {
        this.active = !this.active;
        this.files = $event.dataTransfer.files;
      } else if ($event.type === 'change') {
        this.files = document.querySelector(".dropzoneFile").files;
      }

      for (const property in this.files) {
        // Create a FileReader this.files for each file
        const reader = new FileReader();
        reader.onload = () => {
          // Assign the Data URL to a variable for previewing
          this.previewArray.push(reader.result);
        };

        if (this.backDataType === 'normal') {
          this.backDataMethod(this.files)
        } else {
          this.backDataMethod(this.previewArray)
        }
        // Read the file as a Data URL
        reader.readAsDataURL(this.files[property]);
      }
    }

  },
  computed: {
    isPdf() {
      return (data) => data.substring(5, 20) === 'application/pdf'
    },
    lastArrItem() {
      return this.previewArray[this.previewArray.length - 1]
    }
  },
  mounted() {

  },
};
</script>


<style scoped lang="scss">
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px dashed var(--info);
  transition: 0.3s ease-in-out all;
  border-radius: 4px;
  cursor: pointer;

  label {
    padding: 8px 16px;
    color: #fff;
    background: var(--info);
  }

  #dropzoneFile {
    display: none;
  }
}

.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: var(--info);

  label {
    padding: 8px 16px;
    color: var(--info);
    background: #fff;
  }
}
</style>