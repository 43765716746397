<template>
  <section>
    <v-container>
      <!-- image input -->
      <v-row align="center" :key="galleryImages.length">
        <GenericInput type="dropzone" :value="image" @input="image = $event" label="image" :multi="true" :required="true"
          :isLoading="pageData.isLoading" :cols="[12, 12, 12]">
        </GenericInput>

        <!-- <v-col cols="12">
          <v-btn @click="save()" class="mx-3" color="success" depressed :loading="pageData.isLoading" width="200">
            {{ $t("upload") }}
          </v-btn>
        </v-col> -->

        <DropZone v-if="false" :multi="true" :backDataMethod="(data) => previewArray = data" />
        <!-- {{ previewArray }} -->
      </v-row>
      <!-- end image input -->

      <!-- image cards -->
      <v-row justify="center">
        <v-col cols="12">
          <AnimatedLoading classes="pt-2  pa-5" :isLoading="pageData.isLoading" height="250" :hideText="true" />
        </v-col>
        <v-col cols="12" md="3" v-for="(image, i) in galleryImages" :key="image.id">
          <v-card v-if="!pageData.isLoading" class="pa-1" elevation="2">
            <v-img :src="$api.serverUrl + image.file_path" aspect-ratio="1"></v-img>
            <v-card-actions>
              <v-btn :loading="pageData.isLoading" color="primary" @click="copyLink($api.serverUrl + image.file_path)">
                {{ $t('Copy Link') }}
              </v-btn>
              <v-btn :loading='deleteLoading' color="error" @click="deleteCard(image, i)">
                {{ $t('delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <!-- end image cards -->
    </v-container>
  </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import AnimatedLoading from '@/components/ui/AnimatedLoading.vue';
import DropZone from '@/components/DropZone.vue';

export default {
  name: "ImageEntity",
  data: () => ({
    pageData: {
      screen_code: "03-007",
      url: null,
      // controlRoute: "",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isEdit: false,
      editIsLoading: false,
    },
    copyLoading: false,
    deleteLoading: false,
    valid: false,
    image: [],
    galleryImages: [],
    counter: 0,
    files: null,
    previewURL: null,
    previewArray: []
  }),
  components: {
    GenericInput,
    AnimatedLoading,
    DropZone
  },
  computed: {

  },

  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`image`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            // console.log("image GETresponse", response);
            this.galleryImages = response.data.items
            this.counter = 0;
          }
        });
      } else {
        this.$router.push("/");
      }
    },
    async save() {
      // To send image one by one
      for (let i = 0; i < this.image.length; i++) {
        const response = await this.$api.POST_METHOD(`image`, { image: this.image[i] });
        if (response.check) {
          this.galleryImages.unshift(response.data);
          this.counter = 0;
        }
      }
    },
    // async save() {
    //   // To send image two by two
    //   let response = null;
    //   for (let i = 0; i < this.image.length; i++) {
    //     this.counter++
    //     this.pageData.isLoading = true;
    //     if (this.counter === 2) {
    //       this.counter = 0;
    //       response = await this.$api.POST_METHOD(`image`, { image: this.image[i] });
    //     } else {
    //       response = this.$api.POST_METHOD(`image`, { image: this.image[i] });
    //     }
    //     this.pageData.isLoading = false;
    //     if (response.check) {
    //       this.galleryImages.unshift(response.data);
    //     }
    //   }
    // },
    deleteCard(row) {
      this.counter = 0;
      this.deleteLoading = true;
      this.$api.DELETE_METHOD(`image`, row.id).then((response) => {
        this.deleteLoading = false;
        if (response.check) {
          this.galleryImages = this.galleryImages.filter(e => e.id !== row.id)
          this.image = null
          // this.getData()
        }
      })

    },
    copyLink(link) {
      this.copyLoading = true;
      console.log("Link", link);
      navigator.clipboard.writeText(link)
      setTimeout(() => this.copyLoading = false, 1000)
    },

  },
  watch: {
    image() {
      this.counter++
      if (this.image && this.counter == 1) {
        console.log("image", this.image);
        this.save()
      }
    }

  }

};
</script>