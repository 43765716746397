// Code Module Routes:-

import DepartmentEntity from "@/views/Dashboard/CodeModule/Department/DepartmentEntity.vue";
import DepartmentControl from "@/views/Dashboard/CodeModule/Department/DepartmentControl.vue";
import CategoryEntity from "@/views/Dashboard/CodeModule/Category/CategoryEntity.vue";
import CategoryControl from "@/views/Dashboard/CodeModule/Category/CategoryControl.vue";
import SubCategoryEntity from "@/views/Dashboard/CodeModule/Sub Category/SubCategoryEntity.vue";
import SubCategoryControl from "@/views/Dashboard/CodeModule/Sub Category/SubCategoryControl.vue";
import ProductEntity from "@/views/Dashboard/CodeModule/Product/ProductEntity.vue";
import ProductControl from "@/views/Dashboard/CodeModule/Product/ProductControl.vue";
import BrandEntity from "@/views/Dashboard/CodeModule/Brand/BrandEntity.vue";
import BrandControl from "@/views/Dashboard/CodeModule/Brand/BrandControl.vue";
import AttributeEntity from "@/views/Dashboard/CodeModule/Attribute/AttributeEntity.vue";
import AttributeControl from "@/views/Dashboard/CodeModule/Attribute/AttributeControl.vue";
import TagEntity from "@/views/Dashboard/CodeModule/Tag/TagEntity.vue";
import TagControl from "@/views/Dashboard/CodeModule/Tag/TagControl.vue";
import CollectionEntity from "@/views/Dashboard/CodeModule/Collection/CollectionEntity.vue";
import CollectionControl from "@/views/Dashboard/CodeModule/Collection/CollectionControl.vue";
import CouponEntity from "@/views/Dashboard/CodeModule/Coupon/CouponEntity.vue";
import CouponControl from "@/views/Dashboard/CodeModule/Coupon/CouponControl.vue";
import ProductRateEntity from "@/views/Dashboard/CodeModule/ProductRate/ProductRateEntity.vue";
import ProductRateControl from "@/views/Dashboard/CodeModule/ProductRate/ProductRateControl.vue";
import BranchEntity from "@/views/Dashboard/CodeModule/Branch/BranchEntity.vue";
import BranchControl from "@/views/Dashboard/CodeModule/Branch/BranchControl.vue";
import PaymentMethodsEntity from "@/views/Dashboard/CodeModule/PaymentMethods/PaymentMethodsEntity.vue";
import PaymentMethodsControl from "@/views/Dashboard/CodeModule/PaymentMethods/PaymentMethodsControl.vue";
import ShippingMethodsEntity from "@/views/Dashboard/CodeModule/ShippingMethods/ShippingMethodsEntity.vue";
import ShippingMethodsControl from "@/views/Dashboard/CodeModule/ShippingMethods/ShippingMethodsControl.vue";
import VariantEntity from "@/views/Dashboard/CodeModule/Variant/VariantEntity.vue";
import VariantControl from "@/views/Dashboard/CodeModule/Variant/VariantControl.vue";





export const CodeModuleRoutes = [
  // Department
  {
    path: "/code/department",
    name: "DepartmentEntity",
    component: DepartmentEntity,
    meta: {
      screen_code: "02-001",
    },
  },
  {
    path: "/code/department-control",
    name: "DepartmentControl",
    component: DepartmentControl,
    meta: {
      screen_code: "02-001",
    },
  },
  {
    path: "/code/department-control/:id",
    name: "DepartmentControlEdit",
    component: DepartmentControl,
    meta: {
      screen_code: "02-001",
    },
  },

  // Category
  {
    path: "/code/category",
    name: "CategoryEntity",
    component: CategoryEntity,
    meta: {
      screen_code: "02-002",
    },
  },
  {
    path: "/code/category-control",
    name: "CategoryControl",
    component: CategoryControl,
    meta: {
      screen_code: "02-002",
    },
  },
  {
    path: "/code/category-control/:id",
    name: "CategoryControlEdit",
    component: CategoryControl,
    meta: {
      screen_code: "02-002",
    },
  },

  // Sub Category
  {
    path: "/code/sub_category",
    name: "SubCategoryEntity",
    component: SubCategoryEntity,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/code/sub_category-control",
    name: "SubCategoryControl",
    component: SubCategoryControl,
    meta: {
      screen_code: "02-003",
    },
  },
  {
    path: "/code/sub_category-control/:id",
    name: "SubCategoryControlEdit",
    component: SubCategoryControl,
    meta: {
      screen_code: "02-003",
    },
  },

  // Product
  {
    path: "/code/product",
    name: "ProductEntity",
    component: ProductEntity,
    meta: {
      screen_code: "02-004",
    },
  },
  {
    path: "/code/product-control",
    name: "ProductControl",
    component: ProductControl,
    meta: {
      screen_code: "02-004",
    },
  },
  {
    path: "/code/product-control/:id",
    name: "ProductControlEdit",
    component: ProductControl,
    meta: {
      screen_code: "02-004",
    },
  },
  // Brand
  {
    path: "/code/brand",
    name: "BrandEntity",
    component: BrandEntity,
    meta: {
      screen_code: "02-005",
    },
  },
  {
    path: "/code/brand-control",
    name: "BrandControl",
    component: BrandControl,
    meta: {
      screen_code: "02-005",
    },
  },
  {
    path: "/code/brand-control/:id",
    name: "BrandControlEdit",
    component: BrandControl,
    meta: {
      screen_code: "02-005",
    },
  },
  // Attribute
  {
    path: "/code/attribute",
    name: "AttributeEntity",
    component: AttributeEntity,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/code/attribute-control",
    name: "AttributeControl",
    component: AttributeControl,
    meta: {
      screen_code: "02-007",
    },
  },
  {
    path: "/code/attribute-control/:id",
    name: "AttributeControlEdit",
    component: AttributeControl,
    meta: {
      screen_code: "02-007",
    },
  },
  // Tag
  {
    path: "/code/tag",
    name: "TagEntity",
    component: TagEntity,
    meta: {
      screen_code: "02-006",
    },
  },
  {
    path: "/code/tag-control",
    name: "TagControl",
    component: TagControl,
    meta: {
      screen_code: "02-006",
    },
  },
  {
    path: "/code/tag-control/:id",
    name: "TagControlEdit",
    component: TagControl,
    meta: {
      screen_code: "02-006",
    },
  },
  // Collection
  {
    path: "/code/collection",
    name: "CollectionEntity",
    component: CollectionEntity,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/code/collection-control",
    name: "CollectionControl",
    component: CollectionControl,
    meta: {
      screen_code: "02-008",
    },
  },
  {
    path: "/code/collection-control/:id",
    name: "CollectionControlEdit",
    component: CollectionControl,
    meta: {
      screen_code: "02-008",
    },
  },
  // Coupon
  {
    path: "/code/coupon",
    name: "CouponEntity",
    component: CouponEntity,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/code/coupon-control",
    name: "CouponControl",
    component: CouponControl,
    meta: {
      screen_code: "02-009",
    },
  },
  {
    path: "/code/coupon-control/:id",
    name: "CouponControlEdit",
    component: CouponControl,
    meta: {
      screen_code: "02-009",
    },
  },
  // Product Rate
  {
    path: "/code/product_rate",
    name: "ProductRateEntity",
    component: ProductRateEntity,
    meta: {
      screen_code: "02-010",
    },
  },
  {
    path: "/code/product_rate-control",
    name: "ProductRateControl",
    component: ProductRateControl,
    meta: {
      screen_code: "02-010",
    },
  },
  {
    path: "/code/product_rate-control/:id",
    name: "ProductRateControlEdit",
    component: ProductRateControl,
    meta: {
      screen_code: "02-010",
    },
  },
  // Branch
  {
    path: "/code/branch",
    name: "BranchEntity",
    component: BranchEntity,
    meta: {
      screen_code: "01-007",
    },
  },
  {
    path: "/code/branch-control",
    name: "BranchControl",
    component: BranchControl,
    meta: {
      screen_code: "01-007",
    },
  },
  {
    path: "/code/branch-control/:id",
    name: "BranchControlEdit",
    component: BranchControl,
    meta: {
      screen_code: "01-007",
    },
  },

  // Payment Methods
  {
    path: "/code/payment_methods",
    name: "PaymentMethodsControlEntity",
    component: PaymentMethodsEntity,
    meta: {
      screen_code: "03-015",
    },
  },
  {
    path: "/code/payment_methods-control",
    name: "PaymentMethodsControl",
    component: PaymentMethodsControl,
    meta: {
      screen_code: "03-015",
    },
  },
  {
    path: "/code/payment_methods-control/:id",
    name: "PaymentMethodsControlEdit",
    component: PaymentMethodsControl,
    meta: {
      screen_code: "03-015",
    },
  },

  // Shipping Methods
  {
    path: "/code/shipping_methods",
    name: "ShippingMethodsControlEntity",
    component: ShippingMethodsEntity,
    meta: {
      screen_code: "03-016",
    },
  },
  {
    path: "/code/shipping_methods-control",
    name: "ShippingMethodsControl",
    component: ShippingMethodsControl,
    meta: {
      screen_code: "03-016",
    },
  },
  {
    path: "/code/shipping_methods-control/:id",
    name: "ShippingMethodsControlEdit",
    component: ShippingMethodsControl,
    meta: {
      screen_code: "03-016",
    },
  },
  // Variant
  {
    path: "/code/variants",
    name: "VariantEntity",
    component: VariantEntity,
    meta: {
      screen_code: "02-017",
    },
  },
  {
    path: "/code/variants-control",
    name: "VariantControlControl",
    component: VariantControl,
    meta: {
      screen_code: "02-017",
    },
  },
  {
    path: "/code/variants-control/:id",
    name: "VariantControlEdit",
    component: VariantControl,
    meta: {
      screen_code: "02-017",
    },
  },


];