<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-sheet class="pa-5">

                  <v-row align="center">

                     <GenericInput type="text" :value="form.code" @input="form.code = $event" label="code"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                     <GenericInput type="float" :value="form.minimum" @input="form.minimum = $event" label="minimum"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="date" :value="form.from_date" @input="form.from_date = $event"
                        label="from date" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="date" :minDate="form.from_date" :value="form.to_date"
                        @input="form.to_date = $event" label="to date" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="discount_type" :value="form.discount_type"
                        @input="form.discount_type = $event" label="discount type" :multi="false" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="float" :value="form.discount_value" @input="form.discount_value = $event"
                        label="discount value" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="switch" :value="form.logged_user" @input="form.logged_user = $event"
                        label="logged user" :required="false" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 'auto']">
                     </GenericInput>

                     <GenericInput type="switch" :value="form.discount_product" @input="form.discount_product = $event"
                        label="apply on discount product" :required="false" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 'auto']">
                     </GenericInput>

                     <GenericInput type="switch" :value="form.one_time_for_user"
                        @input="form.one_time_for_user = $event" label="one time for user" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 'auto']">
                     </GenericInput>

                     <GenericInput type="switch" :value="form.one_time_for_all_users"
                        @input="form.one_time_for_all_users = $event" label="one time for all users" :required="false"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 'auto']">
                     </GenericInput>



                  </v-row>
               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "CouponControl",

   data: () => ({
      pageData: {
         screen_code: "02-009",
         url: null,
         controlRoute: "/code/coupon-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      discount_type: [],
      form: {
         minimum: null,
         code: null,
         from_date: null,
         to_date: null,
         logged_user: null,
         one_time_for_user: null,
         one_time_for_all_users: null,
         discount_type: null,
         discount_value: null,
         discount_product: null,
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`coupon/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.departments = response.data.departments;
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`coupon/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response)
                     this.departments = response.data.departments;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }
         this.discount_type = [
            {
               id: 1,
               name: this.$i18n.t("value")
            },
            {
               id: 2,
               name: this.$i18n.t("percent")
            }
         ]

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`coupon/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`coupon`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`coupon/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
