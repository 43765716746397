<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.screen_sub_title_en" @input="form.screen_sub_title_en = $event"
                     label="page name english" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.screen_sub_title_ar" @input="form.screen_sub_title_ar = $event"
                     label="اسم الصفحة عربي" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.screen_code" @input="form.screen_code = $event"
                     label="screen code" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="text" :value="form.url" @input="form.url = $event" label="url" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="mains" :value="form.screen_main_id"
                     @input="form.screen_main_id = $event" label="main" selected_label="title" selected_prop="id"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
                  <GenericInput type="select" :lookups="actions" :value="form.actions" @input="form.actions = $event"
                     label="actions" :multi="true" :required="true" selected_label="name" selected_prop="id"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>
               </v-row>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "ScreenControl",
   data: () => ({
      pageData: {
         screen_code: "01-002",
         url: null,
         controlRoute: "main/screen_sub-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      mains: [],
      actions: [],
      form: {
         screen_sub_title_en: null,
         screen_sub_title_ar: null,
         username: null,
         screen_main_id: null,
         actions: null,
         url: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$route.params.id ? this.$i18n.t('edit') + ' ' + this.$i18n.t('screen') : this.$i18n.t('add new screen');
      },
      getData() {
         this.actions = [
            {
               id: 1,
               name: this.$i18n.t('view')
            },
            {
               id: 2,
               name: this.$i18n.t('add')
            },
            {
               id: 3,
               name: this.$i18n.t('edit')
            },
            {
               id: 4,
               name: this.$i18n.t('delete')
            },
            {
               id: 5,
               name: this.$i18n.t('print')
            },
         ]
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_sub/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.form.actions = this.form.actions.split(',').map(i => Number(i));
                     this.mains = response.data.mains;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`screen_sub/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.mains = response.data.mains;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               // this.form.actions = this.form.actions.toString()
               this.$api.POST_METHOD(`screen_sub/${this.form.screen_sub_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`screen_sub`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`screen_sub/${this.form.screen_sub_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
