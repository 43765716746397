<template>
   <v-row justify="center">
      <v-dialog v-model="pageData.controlDialog" persistent max-width="750">
         <v-card relative class="pa-5">
            <v-form ref="form" v-model="valid">
               <div v-if="!getDataLoading">
                  <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 20px' : 'right: 20px'" small fab
                     color="grey lighten-1" text @click="closeDialog">
                     <v-icon color="error lighten-1">mdi-close</v-icon>
                  </v-btn>
                  <v-card-title class="text-h6 text-center font-weight-bold">
                     <h4 class="d-block font-weight-medium text--disabled">
                        {{ $t("edit") }} {{ pageData.entityName | capitalize }}
                     </h4>
                  </v-card-title>

                  <v-row align="center" class="mt-2">
                     <GenericInput type="select" :lookups="status" :value="form.status" @input="form.status = $event"
                        label="status" :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                     </GenericInput>

                     <GenericInput type="textarea" :value="form.comment_text" @input="form.comment_text = $event"
                        label="note" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-row>

                  <v-row align="center" justify="end">
                     <v-col cols="auto">
                        <v-btn color="success" depressed :width="120" :disabled="!valid" @click="save"
                           class="rounded-lg font-weight-bold white--text" :loading="saveDataLoading">
                           {{ isEdit? $t('save'): $t('create')}}
                        </v-btn>
                     </v-col>
                  </v-row>

                  <v-row align="center" class="pa-3">
                     <v-col cols="12">
                        <h5>{{ $t("previous notes") }} :</h5>
                     </v-col>

                     <v-col cols="12" v-if="comments.length === 0">
                        <h6>{{ $t("there are no previous comments") | capitalize }}</h6>
                     </v-col>

                     <v-row class="comments-section pa-3">
                        <v-card class="mx-auto my-2" width="100%" outlined v-for="comment in comments"
                           :key="comment.id">
                           <v-list-item three-line>
                              <v-avatar size="56">
                                 <LazyImage :height="80" :src="$api.serverUrl + comment.user_image"
                                    :error="require('@/assets/img/svg/default_profile.svg')"
                                    :loading="require('@/assets/img/svg/default_profile.svg')" />
                              </v-avatar>

                              <v-list-item-content class="pa-2">
                                 <div class="d-flex justify-space-between">
                                    <span class="text-button mb-4">
                                       {{ $t("username") }}: {{ comment.full_name }}
                                    </span>
                                    <span class="text-button mb-4">
                                       {{ comment.comment_date }}
                                    </span>
                                 </div>
                                 <v-list-item-subtitle>{{ comment.comment_text }}</v-list-item-subtitle>
                              </v-list-item-content>
                           </v-list-item>
                        </v-card>
                     </v-row>
                  </v-row>
               </div>
               <v-row justify="center" v-if="getDataLoading">
                  <v-col cols="auto" class="my-10">
                     <AnimatedLoading :height="100" :hideText="false" :isLoading="getDataLoading" />
                  </v-col>
               </v-row>
            </v-form>


         </v-card>
      </v-dialog>
   </v-row>
</template>







<script>
import GenericInput from "@/components/ui/GenericInput.vue";
import AnimatedLoading from "@/components/ui/AnimatedLoading.vue";
import LazyImage from "@/components/ui/LazyImage.vue";
// import ChatDialog from "@/components/modals/ChatDialog.vue";
export default {
   name: "ContactUsControl",
   props: {
      backValueMethod: { type: Function },
      isLoading: { default: false },
      pageData: { default: {} },
      rowData: { rowData: {} },
   },
   computed: {
      isEdit() {
         return this.pageData.controlID ? true : false
      }
   },
   components: {
      GenericInput,
      AnimatedLoading,
      LazyImage,
      // ChatDialog
   },
   mounted() {
      this.getData();
      this.form.status = this.rowData.status;
   },
   watch: {
   },
   data: () => ({
      valid: false,
      getDataLoading: false,
      saveDataLoading: false,
      comments: [],
      status: [],
      form: {
         status: null,
         comment_text: null,
      }
   }),
   methods: {
      closeDialog() {
         this.pageData.controlDialog = false;
         this.$refs.form.reset();
      },
      save() {
         if (this.$refs.form.validate()) {
            this.saveDataLoading = true;
            this.form._method = 'PUT';
            this.$api.POST_METHOD(`contact_us/${this.rowData.id}`, this.form).then((response) => {
               this.saveDataLoading = false;
               if (response.check) {
                  this.pageData.controlID = null;
                  this.pageData.controlDialog = false
                  this.$refs.form.reset();
                  this.backValueMethod();
               }
            })


         }



      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
            this.getDataLoading = true;
            this.$api.GET_METHOD(`contact_us/${this.rowData.id}`).then((response) => {
               this.getDataLoading = false;
               if (response.check) {
                  this.status = response.data.items.status;
                  // this.comments = response.data.comments.sort((b, a) => a.id - b.id)
                  this.comments = response.data.comments
                  this.$refs.form.validate();
               }
            })
         } else {
            this.pageData.controlDialog = false;
         }
      }

   },

};
</script>

<style scoped lang="scss">

</style>