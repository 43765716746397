import { render, staticRenderFns } from "./ContactUsControl.vue?vue&type=template&id=31cd700c&scoped=true&"
import script from "./ContactUsControl.vue?vue&type=script&lang=js&"
export * from "./ContactUsControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31cd700c",
  null
  
)

export default component.exports