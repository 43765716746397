<template>
  <section>
    <v-container fluid>
      <!-- Header -->
      <EntityHeader :pageData="pageData" :getData="getData" :getSearch="getSearch" />
      <!-- End Of Header -->

      <!-- Control -->
      <ContactUsControl v-if="pageData.controlDialog" :pageData="pageData" :isLoading="pageData.isLoading"
        :backValueMethod="getData" :rowData="rowData" />
      <!-- End Of Control -->

      <!-- Table -->
      <v-row>
        <v-col cols="12" sm="12">
          <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
            :option="pageData.options" :editValueMethod="edit" :pageData="pageData" :deleteValueMethod="deleteMethod"
            :restoreValueMethod="restoreMethod">
            <template v-slot:td="{ row }">
              <v-btn color="warning" depressed x-small v-if="row.status == 1">{{ $t("pending") }}</v-btn>
              <v-btn color="success" depressed x-small v-else-if="row.status == 2">{{ $t("answered") }}</v-btn>
              <v-btn color="error" depressed x-small v-else>{{ $t("closed") }}</v-btn>
            </template>
          </DynamicTable>
        </v-col>
      </v-row>
      <!-- Pagination -->
      <v-row v-if="!pageData.isLoading && pageData.rows.length > 0">
        <v-col cols="12" sm="12" class="py-0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
      <!-- End of Table -->
    </v-container>
  </section>
</template>

<script>
import EntityHeader from "@/components/ui/EntityHeader.vue";
import Pagination from "@/components/ui/Pagination.vue";
import DynamicTable from "@/components/DynamicTable.vue";
import ContactUsControl from "./ContactUsControl.vue";
export default {
  name: "ContactUsEntity",

  data: () => ({
    pageData: {
      screen_code: "03-005",
      url: null,
      controlRoute: "website/contact_us-control",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      controlID: null,
      controlDialog: false,
      pagination: {},
      queryParam: '?'
    },
    rowData: {}
  }),
  components: {
    EntityHeader,
    Pagination,
    DynamicTable,
    ContactUsControl
  },
  computed: {},
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    }
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 15;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.main = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).main_title;
      this.pageData.category = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).cat_title;
      this.pageData.entityName = this.$global.FilterPermissions(
        this.pageData.screen_code
      ).sub_title;
      this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
      this.pageData.tableHeader = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "name", key: "name", type: 'text', classes: "" },
        { text: "email", key: "email", type: 'text', classes: "" },
        { text: "phone", key: "phone", type: 'text', classes: "" },
        { text: "message", key: "message", type: 'text', classes: "" },
        { text: "subject", key: "subject", type: 'text', classes: "" },
        { text: "status", key: "status", type: 'slot', classes: "" },
        { text: "actions", key: "id", type: 'actions', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`contact_us${this.pageData.queryParam}`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.entityName =
              this.pageData.isTrashed == 0
                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                : this.$store.state.activeScreen.sub_title;
            this.pageData.rows = response.data.items.data || response.data.items;
            this.pageData.page = response.data.items.current_page || 1;
            this.pageData.pagination = {
              page: response.data.items.current_page || 1,
              totalPages: response.data.items.last_page || 1,
              per_page: response.data.items.per_page || 10,
              totalRows: response.data.items.total || this.pageData.rows.length,
            };

          }
        });
      } else {
        this.$router.push("/");
      }
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ""}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    deleteMethod(id) {
      console.log(id);
      this.$api.POST_METHOD(`contact_us/${id}`, { _method: "delete" }).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    restoreMethod(row) {
      this.$api.POST_METHOD(`contact_us_toggle_active/${row.id}`, null).then((response) => {
        if (response.check) {
          this.getData();
        }
      });
    },
    edit(row) {
      console.log("edit", row);
      this.rowData = {};
      this.rowData = row;
      this.pageData.controlDialog = true;
    },
  },
};
</script>
