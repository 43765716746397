<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-sheet class="pa-5">

                  <v-row align="center">

                     <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                     <GenericInput type="text" dir="ltr" :value="form.name_en" @input="form.name_en = $event"
                        label="name_en" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <!-- Test data -->
                     <v-row>
                        <v-col cols="12">
                           <h4>{{ $t("test data") }}</h4>
                        </v-col>
                        <GenericInput v-for="(test, i) in form.test_data" :key="i" type="text"
                           :value="test[filterObjectKey(test)]"
                           @input="form.test_data[i][filterObjectKey(test)] = $event" :label="filterObjectKey(test)"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                        </GenericInput>
                     </v-row>
                     <!-- end Test data -->

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <!-- live data -->
                     <v-row>
                        <v-col cols="12">
                           <h4>{{ $t("live data") }}</h4>
                        </v-col>
                        <GenericInput v-for="(live, i) in form.live_data" :key="i" type="text"
                           :value="live[filterObjectKey(live)]"
                           @input="form.live_data[i][filterObjectKey(live)] = $event" :label="filterObjectKey(live)"
                           :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                        </GenericInput>
                     </v-row>
                     <!-- End Live data -->

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="switch" :value="form.is_installed" @input="form.is_installed = $event"
                        label="publish" :multi="false" :required="false" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="dropzone" :value="form.shipping_image" @input="form.shipping_image = $event"
                        label="gateway image" :multi="false" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>

                  </v-row>
               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "ShippingMethodsControl",

   data: () => ({
      pageData: {
         screen_code: "03-016",
         url: null,
         controlRoute: "/code/shipping_methods-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      form: {
         name_en: null,
         name_ar: null,
         shipping_image: null,
         live_data: {},
         test_data: {},
         is_installed: null,
      },
   }),
   updated() {
      // console.log("categories_filter", this.categories_filter);
   },
   components: {
      GenericInput,
      ControlHeader,
   },
   computed: {
      filterObjectKey() {
         return (key) => Object.keys(key).join("");
      }
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   watch: {
      'form.departments'() {
         this.categories_filter = [];
         this.categories.forEach((category) => {
            this.form.departments.filter(department => { category.department_id == department ? this.categories_filter.push(category) : null })
         })
      },
      'form.categorys'() {
         this.sub_categories_filter = [];
         this.sub_categories.forEach((sub_category) => {
            this.form.categorys.filter(categorys => { sub_category.category_id == categorys ? this.sub_categories_filter.push(sub_category) : null })
         })
      },

   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`shipping_method/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data

                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`shipping_method/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response)
                     this.categories = response.data.categorys;
                     this.sub_categories = response.data.sub_categorys;
                     this.available_statuses = response.data.available_statuses;
                     this.brands = response.data.brands;
                     this.departments = response.data.departments;
                     this.products = response.data.products;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`shipping_method/${this.form.shipping_id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`shipping_method`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`shipping_method/${this.form.shipping_id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
