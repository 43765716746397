<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid && imgValidation"
            :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row>
                  <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                  <GenericInput type="text" dir="ltr" :value="form.name_en" @input="form.name_en = $event"
                     label="name_en" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.ads_code" @input="form.ads_code = $event" label="ads code"
                     :required="false" :isLoading="pageData.editIsLoading" :disabled="true" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.link" @input="form.link = $event" label="link" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
                     label="description_ar" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.description_en"
                     @input="form.description_en = $event" label="description_en" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="dropzone" :value="form.image_ar" @input="form.image_ar = $event" label="image_ar"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="dropzone" dir="ltr" :value="form.image_en" @input="form.image_en = $event"
                     label="image_en" :multi="false" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]">
                  </GenericInput>

               </v-row>


            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "AdsControl",
   data: () => ({
      pageData: {
         screen_code: "03-002",
         url: null,
         controlRoute: "website/ads-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      adsTypes: [],
      form: {
         name_en: null,
         name_ar: null,
         image_ar: null,
         image_en: null,
         description_ar: null,
         description_en: null,
         link: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
      imgValidation() {
         return this.form.image_ar !== null && this.form.image_en !== null ? true : false
      }
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`ads/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`ads/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         this.adsTypes = [
            {
               id: 1,
               name: "test"
            }
         ]
      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`ads/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`ads`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`ads/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },
   },
};
</script>
