<template>
    <v-form ref="form" v-model="valid">
        <v-dialog v-model="openDialog" max-width="500" persistent>
            <v-card relative class="pa-4">

                <!-- Title && Close-->
                <v-card-actions class="justify-end align-center pa-0 mb-8">
                    <v-card-text class="font-weight-bold text-h6 text-capitalize pa-0">
                        {{ $t("add product option") }}
                    </v-card-text>

                    <v-btn small fab color="red5" text @click="close()">
                        <v-icon color="gray6">mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
                <!-- End Title && Close-->

                <v-row align="center" class="ma-0">
                    <!-- Option Master Name -->
                    <v-col cols="12" md="6" lg="6">
                        <v-combobox :label="$t('option name')" item-value="id" item-text="name" class="rounded-lg" outlined
                            :loading="isLoading" clearable dense v-model="masterModel" :items="receiveData"></v-combobox>
                    </v-col>
                    <!-- End Master Option Name -->

                    <!-- List && Color Tab -->
                    <v-col cols="12" sm="6" class="mt-n1">
                        <v-tabs v-model="tab" id="variant_tab" grow active-class="green10" height="40" color="black">
                            <v-tab class="rounded-lg">
                                <v-icon>mdi-list-box-outline </v-icon>
                                {{ $t("List") }}
                            </v-tab>
                            <v-tab class="rounded-lg">
                                <v-icon>mdi-invert-colors</v-icon>
                                {{ $t("Color") }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <!-- End List && Color Tab -->

                    <!-- Option Name Details -->
                    <v-col cols="12">
                        <v-combobox v-model="detailsModel" :hide-no-data="!search" outlined dense :items="form.details"
                            :search-input.sync="search" hide-selected clearable class="rounded-lg"
                            :label="$t('Type in choices for this option')" item-text="name" item-value="id" multiple
                            small-chips>
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" label small>
                                    <v-badge v-if="tab === 1" :inline="true" dot :color="`${item.color_code || 'black'} `">
                                    </v-badge>
                                    <span class="px-2">{{ item.name }}</span>
                                    <v-icon small @click="parent.selectItem(item)">
                                        $delete
                                    </v-icon>
                                </v-chip>
                            </template>
                        </v-combobox>

                    </v-col>
                    <!-- End Option Name Details -->

                    <v-col cols="12">
                        <v-color-picker v-if="openColorPicker" v-click-outside="onClickOutside" v-model="color"
                            dot-size="24" hide-mode-switch mode="hexa" swatches-max-height="100">
                        </v-color-picker>
                    </v-col>

                    <!-- Add && Close-->
                    <v-card-actions class="justify-end flex-grow-1">
                        <v-btn class="error--text" text width="100" @click="close()" :loading="saveLoading">
                            {{ $t('cancel') }}
                        </v-btn>
                        <v-btn class="blue12 blue3--text" width="100" :disabled="validationInputs" text @click="save()"
                            :loading="saveLoading">
                            {{ $t('add') }}
                        </v-btn>
                    </v-card-actions>
                    <!--End Add && Close-->
                </v-row>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>


export default {
    name: "ProductOptionsDialog",
    data: () => ({
        form: {
            id: null,
            is_color: null,
            name: [],
            details: []
        },
        valid: false,
        isLoading: false,
        saveLoading: false,
        openColorPicker: false,
        tab: 0,
        search: null,
        receiveData: [],
        detailsModel: [],
        masterModel: [],
        type: 'hex',
        hex: '#FF00FF',
        hexa: '#FF00FFFF',
    }),
    props: {
        openDialog: { default: false },
        closeDialog: { type: Function },
        refreshData: { type: Function },
        callBackMethod: { type: Function },
    },
    methods: {
        save() {
            this.form.details = this.detailsModel;
            this.form.is_color = this.tab;
            console.log("form", this.form);
            if (!this.validationInputs) {
                this.form.product_id = +this.$route.params.id;
                this.saveLoading = true;
                this.$api.POST_METHOD(`product_variant`, this.form).then((response) => {
                    this.saveLoading = false;
                    if (response.check) {
                        console.log("product_variant response", response);
                        // this.callBackMethod(response.data);
                        this.refreshData();
                        this.close()
                    }
                })
            }
        },
        close() {
            this.masterModel = [];
            this.detailsModel = [];
            this.closeDialog();
        },
        onClickOutside() {
            this.openColorPicker = false;
        },
        getData() {
            this.isLoading = true;
            this.$api.GET_METHOD(`product_variant/create`).then((response) => {
                if (response.check) {
                    this.isLoading = false;
                    console.log("response", response);
                    this.receiveData = response.data.variants;
                }
            })
        },
    },
    watch: {
        showColor() {
            // for update color in object while add new color
            this.detailsModel.at(-1).color_code = this.showColor
        },
        tab() {
            this.detailsModel = [];
        },
        'form.id'() {
            this.detailsModel = [];
        },
        openDialog() {
            if (this.openDialog) {
                // receive API Data while open Dialog
                this.getData();
            }
        },
        detailsModel(val, prev) {
            if (val.length === prev.length) return

            this.detailsModel = val.map(value => {
                if (typeof value === 'string') {
                    if (this.tab == 1) {
                        this.openColorPicker = true;
                        // for update color in object while add new color
                        value = {
                            id: 0,
                            name: value,
                            color_code: this.showColor
                        }
                    } else {
                        value = {
                            id: 0,
                            name: value,
                            color_code: null
                        }
                    }

                }
                return value
            })

        },
        masterModel(val) {
            this.form = { ...val }
            this.tab = +val.is_color
            console.log(typeof val === 'object', val);
            if (typeof val === 'string') {
                //will add new va\riant
                this.form = {
                    id: 0,
                    name: val,
                    is_color: this.tab
                }
            }
            else if (typeof val === 'object') {
                //will update current variant
                this.form = {
                    id: val.id,
                    name: val.name,
                    is_color: val.is_color,
                    details: val.details
                }
            }

        },

    },
    computed: {
        color: {
            get() {
                return this[this.type]
            },
            set(v) {
                this[this.type] = v
            },
        },
        showColor() {
            if (typeof this.color === 'string') return this.color

            return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
                color[key] = Number(this.color[key].toFixed(2))
                return color
            }, {}), null, 2)
        },
        validationInputs() {
            if (this.masterModel) {
                return this.masterModel.length == 0 || this.detailsModel.length == 0
            } else return true
        }

    }

}
</script>

<style lang="scss" scoped>
.tab-parent.d-flex {
    border: 1px solid;
}

.v-color-picker.v-sheet.theme--light.theme--light {
    border: 1px solid rgba(128, 128, 128, 0.029);
    height: 313px;
}

.color-Fixed-under {
    position: relative;
    z-index: 200;
}

.color-Fixed {
    position: relative;
    z-index: 5000;
}

#variant_tab {
    // border: 1px solid #808080d4;
    // border-radius: 15px;
}
</style>
