<template>
  <span>
    <v-file-input v-model="input" rounded-md :loading="isLoading" :solo="solo" outlined :reverse="reverse"
      :dense="dense" :name="name" @keydown.enter="keydownEnter()" :class="classes"
      :rules="required == true ? $global.requiredRule : $global.notRequiredRule"
      @change="onImageChange($event)" :multiple="multi ? true : false" :disabled="disabled" :color="color"
      :append-icon="icon" chips :hide-details="hideDetails == true ? 'auto' : false" small-chips truncate-length="20">
      <template v-slot:label>
        <label v-if="labelInline == false">
          {{ $t(label) }}
          <span class="error--text" v-if="required == true">*</span>
        </label>
      </template>
      <template v-slot:selection>
        <span v-if="input">{{ $t('image loaded , you can delete or update it') }}</span>
      </template>
      <template v-slot:append-outer>
        <slot name="append-outer" />
      </template>
      <template v-slot:append>
        <slot name="append" />
      </template>
      <template v-slot:prepend-inner>
        <slot name="prepend-inner" />
      </template>
      <template v-slot:prepend>
        <slot name="prepend" />
      </template>
    </v-file-input>


    <div v-if="hideImage == false && multi != true && input" class="overflow-hidden rounded-lg my-2">
      <div v-lazy-container="{ selector: 'img' }"
        v-if="fileExtension == 'jpeg' || fileExtension == 'jpg' || fileExtension == 'png' || fileExtension == 'gif' || fileExtension == 'svg'">
        <img class="d-block ma-auto" style="max-height: 200px;" :key="input"
          :data-src="String(input).substring(0, 10) == 'data:image' ? input : $api.serverUrl + input" alt="qarat" />
      </div>
      <div v-else>
        <img v-if="fileExtension !== 'pdf' && fileExtension !== 'excell'"
          :src="require(`@/assets/img/png/files/${fileExtension}.png`)" height="200" class="d-block ma-auto"
          alt="qarat" />
        <div v-if="fileExtension == 'pdf'">
          <iframe width='100%' height='350' :src='input' />
        </div>
        <div v-if="fileExtension == 'excell'">
          <ExcelTable />
        </div>
      </div>
    </div>

  </span>
</template>


<script>
import ExcelTable from '../ui/ExcelTable.vue';
export default {
  name: "FileInput",
  props: {
    name: { default: null },
    label: { default: null },
    type: { default: null },
    value: { default: null },
    required: { default: false },
    dense: { default: true },
    onChange: { type: Function },
    isLoading: { default: null },
    disabled: { default: null },
    color: { default: "secondary" },
    icon: { default: "" },
    hideDetails: { default: false },
    labelInline: { default: false },
    clearable: { default: false },
    hideImage: { default: false },
    onClickOuter: { type: Function },
    keydownEnter: { type: Function },
    placeholder: { default: null },
    solo: { default: false },
    classes: { default: null },
    dir: { default: null },
    reverse: { default: false },
    multi: { default: false },
  },
  data: () => ({
    selectedImages: [],
    selectedImage: null,
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    fileExtension() {
      if (this.input) {
        return this.$global.FILE_TYPE(this.input);
      } else {
        return null
      }
    }
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy: function () {

  },
  methods: {
    async onImageChange(image) {
      var reader = new FileReader();
      let multiImage = this.multi;
      var base64arr = [];
      var images = new Promise(function (resolve, reject) {
        reader.readAsDataURL(image);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          reject(error);
          alert(error);
        };
      });
      if (await images) {
        if (multiImage == true) {
          base64arr.push(await images);
          this.selectedImages.push(await images);
          // console.log('aaa', this.selectedImages);
          this.$emit("input", this.selectedImages);
          this.onClickOuter(
            this.name,
            this.input,
            this.selectedImages.toString(),
            this.type,
            this.required
          );
        }
        if (multiImage == null || multiImage == false) {
          this.selectedImage = await images;
          // console.log('bbbb', this.selectedImage);
          this.$emit("input", this.selectedImage);
          this.onClickOuter(
            this.name,
            this.input,
            this.selectedImage,
            this.type,
            this.required
          );
          this.$refs.myVueDropzone.disable();
        }
      }
    },
  },
  components:{
    ExcelTable,
  }
};
</script>



<style scoped lang="scss">

</style>
