<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <!-- <GenericInput type="text" :value="form.seo_code" @input="form.seo_code = $event" label="SEO Page"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]" /> -->

                  <!-- <GenericInput type="text" :value="form.hid" @input="form.hid = $event" label="HID"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" /> -->
                  <GenericInput type="select" :lookups="seo_pages" :value="form.seo_page_id"
                     @input="form.seo_page_id = $event" label="SEO Page" selected_label="name" selected_prop="id"
                     :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="select" :lookups="types" :value="form.type" @input="form.type = $event"
                     label="type" selected_label="type" :multi="false" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="text" v-if="form.name !== 'keywords'" :value="form.content_ar"
                     @input="form.content_ar = $event" label="content بالعربي" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="text" v-if="form.name !== 'keywords'" :value="form.content_en"
                     @input="form.content_en = $event" label="content in english" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="combobox" :multi="true" :lookups="form.content_ar" v-if="form.name == 'keywords'"
                     :value="form.content_ar" @input="form.content_ar = $event" label="content بالعربي" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

                  <GenericInput type="combobox" :multi="true" :lookups="form.content_en" v-if="form.name == 'keywords'"
                     :value="form.content_en" @input="form.content_en = $event" label="content in english"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]" />

               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "SEOControl",
   data: () => ({
      pageData: {
         screen_code: "03-010",
         url: null,
         controlRoute: "website/seo-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      types: [],
      seo_pages: [],
      form: {
         hid: null,
         name: null,
         content_ar: null,
         content_en: null,
         seo_page_id: null,
         type: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {

   },
   watch: {
      'form.type'() {
         this.form.name = this.form.type.type;
         this.form.seo_type_id = this.form.type.id;
         this.form.hid = this.form.type.type;
      }
   },
   mounted() {

      this.pageMainData();
      this.formatDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`seo/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.seo_pages = response.data.seo_pages;
                     this.types = response.data.seo_types;
                     this.form.type = this.$global.FilterArrayOfObject(response.data.seo_types, 'id', response.data.data.seo_type_id)[0]
                     this.form.content_ar = this.form.content_ar.split(',').map(i => i);
                     this.form.content_en = this.form.content_en.split(',').map(i => i);
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`seo/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.seo_pages = response.data.seo_pages;
                     this.types = response.data.seo_types;
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`seo/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`seo`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      formatDate() {
         var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2)
            month = '0' + month;
         if (day.length < 2)
            day = '0' + day;
         this.form.start_date = [year, month, day].join('-');
         this.form.end_date = [year, month, day].join('-');
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`seo/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
