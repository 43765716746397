// TM Module Routes:-
import HomeModule from "@/views/Dashboard/HomeModule/HomeModule";
import MainModule from "@/views/Dashboard/MainModule/MainModule";
import CodeModule from "@/views/Dashboard/CodeModule/CodeModule";
import WebsiteModule from "@/views/Dashboard/WebsiteModule/WebsiteModule";
import BookingModule from "@/views/Dashboard/BookingModule/BookingModule";
import OthersModule from "@/views/Dashboard/OthersModule/OthersModule";

// Child
import {
  HomeModuleRoutes
} from "./HomeModuleRoutes";
import {
  MainModuleRoutes
} from "./MainModuleRoutes";
import {
  CodeModuleRoutes
} from "./CodeModuleRoutes";
import {
  WebsiteModuleRoutes
} from "./WebsiteModuleRoutes";
import {
  OthersModuleRoutes
} from "./OthersModuleRoutes";
import {
  BookingModuleRoutes
} from "./BookingModuleRoutes";


export const DashboardRoutes = [{
    path: "/",
    name: "HomeModule",
    component: HomeModule,
    meta: {
      title: "home",
    },
    children: [...HomeModuleRoutes],
  },
  {
    path: "/main",
    name: "MainModule",
    component: MainModule,
    meta: {
      title: "main",
    },
    children: [...MainModuleRoutes],
  },
  {
    path: "/code",
    name: "CodeModule",
    component: CodeModule,
    meta: {
      title: "code",
    },
    children: [...CodeModuleRoutes],
  },
  {
    path: "/website",
    name: "WebsiteModule",
    component: WebsiteModule,
    meta: {
      title: "website",
    },
    children: [...WebsiteModuleRoutes],
  },
  {
    path: "/booking",
    name: "BookingModule",
    component: BookingModule,
    meta: {
      title: "booking",
    },
    children: [...BookingModuleRoutes],
  },
  {
    path: "/others",
    name: "OthersModule",
    component: OthersModule,
    meta: {
      title: "others",
    },
    children: [...OthersModuleRoutes],
  },

];