// Main Module Routes:-

import MainMenuEntity from "@/views/Dashboard/MainModule/MainMenu/MainMenuEntity.vue";
import MainMenuControl from "@/views/Dashboard/MainModule/MainMenu/MainMenuControl.vue";
import ScreenEntity from "@/views/Dashboard/MainModule/Screens/ScreenEntity.vue";
import ScreenControl from "@/views/Dashboard/MainModule/Screens/ScreenControl.vue";
import UserEntity from "@/views/Dashboard/MainModule/Users/UserEntity.vue";
import UserControl from "@/views/Dashboard/MainModule/Users/UserControl.vue";
import PermissionsEntity from "@/views/Dashboard/MainModule/PermissionsEntity/PermissionsEntity";
import PermissionsControl from "@/views/Dashboard/MainModule/PermissionsEntity/PermissionsControl";
import JobTitleEntity from "@/views/Dashboard/MainModule/JobTitle/JobTitleEntity";
import JobTitleControl from "@/views/Dashboard/MainModule/JobTitle/JobTitleControl";
import GeneralSettings from "@/views/Dashboard/MainModule/GeneralSettings/GeneralSettings";
import SystemDesign from "@/views/Dashboard/MainModule/SystemDesign/SystemDesign";
import CitiesEntity from "@/views/Dashboard/MainModule/Cities/CitiesEntity";
import CitiesControl from "@/views/Dashboard/MainModule/Cities/CitiesControl";
import CountriesEntity from "@/views/Dashboard/MainModule/Countries/CountriesEntity";
import CountriesControl from "@/views/Dashboard/MainModule/Countries/CountriesControl";
import CurrenciesEntity from "@/views/Dashboard/MainModule/Currencies/CurrenciesEntity";
import CurrenciesControl from "@/views/Dashboard/MainModule/Currencies/CurrenciesControl";

export const MainModuleRoutes = [
  // Main Menu
  {
    path: "/main/main_menu",
    name: "MainMenuEntity",
    component: MainMenuEntity,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/main_menu-control",
    name: "ScreenControl",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  {
    path: "/main/main_menu-control/:id",
    name: "MainMenuControlEdit",
    component: MainMenuControl,
    meta: {
      screen_code: "01-001",
    },
  },
  // Screens
  {
    path: "/main/screen_sub",
    name: "ScreenEntity",
    component: ScreenEntity,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_sub-control",
    name: "ScreenControl",
    component: ScreenControl,
    meta: {
      screen_code: "01-002",
    },
  },
  {
    path: "/main/screen_sub-control/:id",
    name: "ScreenControlEdit",
    component: ScreenControl,
    meta: {
      screen_code: "01-002",
    },
  },
  // Users
  {
    path: "/main/user",
    name: "UserEntity",
    component: UserEntity,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/user-control",
    name: "UserControl",
    component: UserControl,
    meta: {
      screen_code: "01-003",
    },
  },
  {
    path: "/main/user-control/:id",
    name: "UserControlEdit",
    component: UserControl,
    meta: {
      screen_code: "01-003",
    },
  },

  // permissions
  {
    path: "/main/user_permission",
    name: "PermissionsEntity",
    component: PermissionsEntity,
    meta: {
      screen_code: "01-004",
    },
  },
  {
    path: "/main/permissions-control/:id",
    name: "PermissionsControl",
    component: PermissionsControl,
    meta: {
      screen_code: "01-004",
    },
  },
  // Job Title 
  {
    path: "/main/title",
    name: "JobTitleEntity",
    component: JobTitleEntity,
    meta: {
      screen_code: "01-005",
    },
  },
  {
    path: "/main/title-control",
    name: "JobTitleControl",
    component: JobTitleControl,
    meta: {
      screen_code: "01-005",
    },
  },
  {
    path: "/main/title-control/:id",
    name: "JobTitleControlEdit",
    component: JobTitleControl,
    meta: {
      screen_code: "01-005",
    },
  },
  // General Settings
  {
    path: "/main/settings/:id",
    name: "GeneralSettings",
    component: GeneralSettings,
    meta: {
      screen_code: "01-006",
    },
  },
  // SystemDesign
  {
    path: "/main/system_design",
    name: "SystemDesign",
    component: SystemDesign,
    meta: {
      screen_code: "00-000",
    },
  },

  // Cities
  {
    path: "/main/cities",
    name: "CitiesEntity",
    component: CitiesEntity,
    meta: {
      screen_code: "01-009",
    },
  },
  {
    path: "/main/cities-control",
    name: "CitiesControl",
    component: CitiesControl,
    meta: {
      screen_code: "01-009",
    },
  },
  {
    path: "/main/cities-control/:id",
    name: "CitiesControlEdit",
    component: CitiesControl,
    meta: {
      screen_code: "01-009",
    },
  },

  // Countries
  {
    path: "/main/countries",
    name: "CountriesEntity",
    component: CountriesEntity,
    meta: {
      screen_code: "01-008",
    },
  },
  {
    path: "/main/countries-control",
    name: "CountriesControl",
    component: CountriesControl,
    meta: {
      screen_code: "01-008",
    },
  },
  {
    path: "/main/countries-control/:id",
    name: "CountriesControlEdit",
    component: CountriesControl,
    meta: {
      screen_code: "01-008",
    },
  },

  // Currencies
  {
    path: "/main/currencies",
    name: "CurrenciesEntity",
    component: CurrenciesEntity,
    meta: {
      screen_code: "01-007",
    },
  },
  {
    path: "/main/currencies-control",
    name: "CurrenciesControl",
    component: CurrenciesControl,
    meta: {
      screen_code: "01-007",
    },
  },
  {
    path: "/main/currencies-control/:id",
    name: "CurrenciesControlEdit",
    component: CurrenciesControl,
    meta: {
      screen_code: "01-007",
    },
  },
];