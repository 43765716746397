<template>
  <section>
    <v-container fluid class="pa-0">
      <v-container fluid class="pb-0 mt-2 px-5">

        <iframe src="http://theconceptsa.com/" width="100%" :height="$store.state.Settings.windowSize.y - 100" frameborder="0"></iframe>
      </v-container>
    </v-container>
  </section>
</template>

<script>

export default {
  name: "Home",
  computed: {

  },
  components: {

  },
  data() {
    return {
      date: new Date(),
      greeting: '',

    };
  },
  watch: {},
  mounted() {
    this.getHomeData()
  },
  methods: {
    getHomeData() {
      let time = new Date().getHours();
      if (time >= 0 && time < 12) {
        this.greeting = this.$i18n.t("Good Morning");
      } else if (time == 12) {
        this.greeting = this.$i18n.t("Good Noon");
      } else if (time >= 12 && time <= 17) {
        this.greeting = this.$i18n.t("Good Afternoon");
      } else {
        this.greeting = this.$i18n.t("Good Evening");
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>
