<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5 px-0">
            <v-card class="backgroundW shadow px-3"  :height="$store.state.Settings.windowSize.y - 220">
               <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <v-tab>
                     {{ 'light mode'  | capitalize}}
                  </v-tab>
                  <v-tab>
                     {{ 'dark mode'  | capitalize}}
                  </v-tab>
                  <v-tab>
                     {{ 'logo'  | capitalize}}
                  </v-tab>
                  <v-tab>
                     {{ 'system'  | capitalize}}
                  </v-tab>
               </v-tabs>
               <v-col cols="12" class="mt-n3">
                  <v-divider />
               </v-col>
               <v-tabs-items class="py-5" v-model="tab" :height="$store.state.Settings.windowSize.y - 220">

                  <v-tab-item class="mt-3 pb-5">
                     <v-row align="center">
                        <v-col cols="auto" class="d-flex align-center" v-for="(color, index) in Object.keys(form.light)"
                           :key="index">
                           <label class="mx-2" for="head">{{ color | capitalize}}</label>
                           <input type="color" id="color" v-model="form.light[color]" :name="color">
                        </v-col>
                     </v-row>
                  </v-tab-item>

                  <v-tab-item class="mt-3 pb-5">
                     <v-row align="center">
                        <v-col cols="auto" class="d-flex justify-space-between align-center"
                           v-for="(color, index) in Object.keys(form.dark)" :key="index">
                           <label class="mx-2" for="head">{{ color | capitalize}}</label>
                           <input type="color" id="color" v-model="form.dark[color]" :name="color">
                        </v-col>
                     </v-row>
                  </v-tab-item>

                  <v-tab-item class="mt-3 pb-5">
                     <v-row align="center">
                        <GenericInput type="dropzone" :value="form.logo" @input="form.logo = $event" label="Logo"
                           :required="true" :isLoading="false" :cols="[12, 12, 6]">
                        </GenericInput>
                        <GenericInput type="dropzone" :value="form.loader" @input="form.loader = $event" label="GIF Loader"
                           :required="true" :isLoading="false" :cols="[12, 12, 6]">
                        </GenericInput>
                     </v-row>
                  </v-tab-item>

                  <v-tab-item class="mt-3 pb-5">


                     <v-row align="center">
                        <GenericInput type="switch" :reverse="true" :value="form.default_dark_theme" @input="form.default_dark_theme = $event" label="Dark Theme Is Default"
                           :required="false" :isLoading="false" :cols="[4,3,3]">
                        </GenericInput>
                        <GenericInput type="switch" :reverse="true" :value="form.dark_side_bar" @input="form.dark_side_bar = $event" label="Dark Sidebar"
                           :required="false" :isLoading="false" :cols="[4,3,3]">
                        </GenericInput>
                        <GenericInput type="switch" :reverse="true" :value="form.dark_app_bar" @input="form.dark_app_bar = $event" label="Dark Appbar"
                           :required="false" :isLoading="false" :cols="[4,3,3]">
                        </GenericInput>                
                        <GenericInput type="switch" :reverse="true" :value="form.side_bar" @input="form.side_bar = $event" label="Hide Sidebar"
                           :required="false" :isLoading="false" :cols="[4,3,3]">
                        </GenericInput>              
                        <GenericInput type="switch" :reverse="true" :value="form.app_bar" @input="form.app_bar = $event" label="Appbar"
                           :required="false" :isLoading="false" :cols="[4,3,3]">
                        </GenericInput>       
                        <v-col cols="12" class="my-2">
                           <v-divider />
                        </v-col>
                        <GenericInput type="text" :reverse="true" :value="form.tabel_column_padding" @input="form.tabel_column_padding = $event" label="Tabel Column Padding"
                           :required="true" :isLoading="false" :cols="[6,4,3]" />

                        <GenericInput type="text" :reverse="true" :value="form.input_padding_x" @input="form.input_padding_x = $event" label="Input Padding X"
                           :required="true" :isLoading="false" :cols="[6,4,3]" />

                        <GenericInput type="text" :reverse="true" :value="form.input_padding_y" @input="form.input_padding_y = $event" label="Input Padding Y"
                           :required="true" :isLoading="false" :cols="[6,4,3]">
                        </GenericInput>
                     </v-row>
                  </v-tab-item>

               </v-tabs-items>
            </v-card>

         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>

import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from "@/components/ui/GenericInput.vue";
export default {
   name: "SystemDesign",
   data: () => ({
      pageData: {
         screen_code: "00-000",
         url: null,
         controlRoute: "others/apply-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: false,
         isEdit: false,
         editIsLoading: false,
      },
      tab: 0,
      valid: false,
      users: [],
      jobs: [],
      form: {
         light: {
            primary: null,
            secondary: null,
            accent: null,
            error: null,
            info: null,
            success: null,
            warning: null,
            backgroundD: null,
            backgroundW: null,
            light: null,
         },
         dark: {
            primary: null,
            secondary: null,
            accent: null,
            error: null,
            info: null,
            success: null,
            warning: null,
            backgroundD: null,
            backgroundW: null,
            light: null,
         },
         default_dark_theme: 0,
         dark_side_bar: 0,
         dark_app_bar: 0,
         logo: null,
         loader: null,
         side_bar: 1,
         app_bar: 0,
         tabel_column_padding: '5px',
         input_padding_x: '5px',
         input_padding_y: '5px',
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = '';
         this.pageData.category = '';
         this.pageData.url = '';
         this.pageData.entityName = 'theme managment';
      },
      getData() {
         this.form = {...this.$store.state.Settings.custom_theme}
         Object.keys(this.form.light).forEach(color => {
            this.form.light[color] = this.$vuetify.theme.themes.light[color]
         })
         Object.keys(this.form.dark).forEach(color => {
            this.form.dark[color] = this.$vuetify.theme.themes.dark[color]
         })

      },
      save() {
         Object.keys(this.form.light).forEach(color => {
            this.$vuetify.theme.themes.light[color] = this.form.light[color]
         })
         Object.keys(this.form.dark).forEach(color => {
            this.$vuetify.theme.themes.dark[color] = this.form.dark[color]
         })
         this.$store.dispatch("Settings/CUSTOM_THEME", this.form)
      },


   },
};
</script>
