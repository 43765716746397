import vuetify from "../plugins/vuetify";
import i18n from "../plugins/i18n";

export default {
  namespaced: true,
  state: {
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    translation: localStorage.getItem("language") == "en" ? i18n.messages.en : i18n.messages.ar,
    windowSize: { x: 0, y: 0 },
    key_clicked : null,
    isRTL: vuetify.rtl,
    darkTheme: localStorage.getItem("darkTheme") == "true" ? true : false,
    favourites: localStorage.getItem("favourites")
      ? JSON.parse(localStorage.getItem("favourites"))
      : [],
      custom_theme :{
        light: {
          primary: null,
          secondary: null,
          accent: null,
          error: null,
          info: null,
          success: null,
          warning: null,
          backgroundD: null,
          backgroundW: null,
          light: null,
       },
       dark: {
          primary: null,
          secondary: null,
          accent: null,
          error: null,
          info: null,
          success: null,
          warning: null,
          backgroundD: null,
          backgroundW: null,
          light: null,
       },
        default_dark_theme:0,
        dark_side_bar:0,
        dark_app_bar:0,
        logo : null,
        loader : null,
        side_bar : true,
        app_bar:0,
        tabel_column_padding:'5px',
        tabel_header_bg:null,
        input_padding_x:'5px',
        input_padding_y:'5px',
      },
  },
  mutations: {
    DARK_THEME(state, status) {
      state.darkTheme = status;
    },
    CUSTOM_THEME(state, theme) {
      state.custom_theme = theme;
      state.darkTheme = theme.default_dark_theme ? true : false;
      vuetify.framework.theme.dark = theme.default_dark_theme ? true : false
      localStorage.setItem("darkTheme", theme.default_dark_theme ? true : false);
    },
    SET_KEY_CLICKED(state, key) {
      state.key_clicked = key;
    },
    ACTIVE_LANGUAGE(state, lang) {
      state.language = lang;
      state.translation = lang == "ar" ? i18n.messages.ar : i18n.messages.en;
      (i18n.locale = lang);
    },
    UPDATE_FAVOURITES(state, favourites) {
      state.favourites = favourites;
      setTimeout(() => {
        localStorage.setItem("favourites", JSON.stringify(state.favourites));
      }, 10);
    },
  },
  actions: {
    changeLanguage({ state }) {
      if (state.language == "en") {
        localStorage.setItem("language", "ar");
        window.location.reload(true);
      } else {
        localStorage.setItem("language", "en");
        window.location.reload(true);
      }
    },
    darkTheme({ commit, state }) {
      localStorage.setItem("darkTheme", !state.darkTheme);
      commit("DARK_THEME", !state.darkTheme);
      return (vuetify.framework.theme.dark = state.darkTheme);
    }, 
    CUSTOM_THEME({ commit } , theme) {
      commit("CUSTOM_THEME", theme);
    },
    addFavourites({ commit, state }, favourites) {
      if (!state.favourites.some((fav) => fav.url === favourites.url)) {
        return commit("UPDATE_FAVOURITES", state.favourites.concat(favourites));
      } else {
        const removeIndex = state.favourites.findIndex(
          (fav) => fav.url === favourites.url
        );
        state.favourites.splice(removeIndex, 1);
        return commit("UPDATE_FAVOURITES", state.favourites);
      }
    },
  },
};
