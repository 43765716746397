<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">
                  <GenericInput type="text" :value="form.name_en" @input="form.name_en = $event" label="name_ar"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="text" dir="ltr" :value="form.name_ar" @input="form.name_ar = $event"
                     label="name_en" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.job_ar" @input="form.job_ar = $event" label="job name arabic"
                     :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="text" dir="ltr" :value="form.job_en" @input="form.job_en = $event"
                     label="Job Name English" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
                     label="description_ar" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.description_en"
                     @input="form.description_en = $event" label="description_en" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>
               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "TestimonialControl",
   data: () => ({
      pageData: {
         screen_code: "03-013",
         url: null,
         controlRoute: "website/testimonial-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      users: [],
      transactions: [],
      form: {
         name_en: null,
         name_ar: null,
         description_ar: null,
         description_en: null,
         job_ar: null,
         job_en: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.formatDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`testimonial/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`testimonial/create`).then(() => {
                  this.pageData.isLoading = false;
                  // if (response.check) {


                  // }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`testimonial/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`testimonial`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      formatDate() {
         var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2)
            month = '0' + month;
         if (day.length < 2)
            day = '0' + day;
         this.form.start_date = [year, month, day].join('-');
         this.form.end_date = [year, month, day].join('-');
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`testimonial/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
