// Booking Module Routes:-

import BranchsEntity from "@/views/Dashboard/BookingModule/Branchs/BranchsEntity.vue";
import BranchsControl from "@/views/Dashboard/BookingModule/Branchs/BranchsControl.vue";
import CategoryEntity from "@/views/Dashboard/BookingModule/Category/CategoryEntity.vue";
import CategoryControl from "@/views/Dashboard/BookingModule/Category/CategoryControl.vue";
import CategoryTypeEntity from "@/views/Dashboard/BookingModule/CategoryType/CategoryTypeEntity.vue";
import CategoryTypeControl from "@/views/Dashboard/BookingModule/CategoryType/CategoryTypeControl.vue";
import ServiceEntity from "@/views/Dashboard/BookingModule/Service/ServiceEntity.vue";
import ServiceControl from "@/views/Dashboard/BookingModule/Service/ServiceControl.vue";




export const BookingModuleRoutes = [
  // Branchs
  {
    path: "/booking/branch",
    name: "BranchsEntity",
    component: BranchsEntity,
    meta: {
      screen_code: "04-001",
    },
  },
  {
    path: "/booking/branch-control",
    name: "BranchsControl",
    component: BranchsControl,
    meta: {
      screen_code: "04-001",
    },
  },
  {
    path: "/booking/branch-control/:id",
    name: "BranchsControlEdit",
    component: BranchsControl,
    meta: {
      screen_code: "04-001",
    },
  },
  // Category
  {
    path: "/booking/category",
    name: "CategoryEntity",
    component: CategoryEntity,
    meta: {
      screen_code: "04-002",
    },
  },
  {
    path: "/booking/category-control",
    name: "CategoryControl",
    component: CategoryControl,
    meta: {
      screen_code: "04-002",
    },
  },
  {
    path: "/booking/category-control/:id",
    name: "CategoryControlEdit",
    component: CategoryControl,
    meta: {
      screen_code: "04-002",
    },
  },
  // CategoryType
  {
    path: "/booking/category_type",
    name: "CategoryTypeEntity",
    component: CategoryTypeEntity,
    meta: {
      screen_code: "04-003",
    },
  },
  {
    path: "/booking/category_type-control",
    name: "CategoryTypeControl",
    component: CategoryTypeControl,
    meta: {
      screen_code: "04-003",
    },
  },
  {
    path: "/booking/category_type-control/:id",
    name: "CategoryTypeControlEdit",
    component: CategoryTypeControl,
    meta: {
      screen_code: "04-003",
    },
  },
  // Service
  {
    path: "/booking/service",
    name: "ServiceEntity",
    component: ServiceEntity,
    meta: {
      screen_code: "04-004",
    },
  },
  {
    path: "/booking/service-control",
    name: "ServiceControl",
    component: ServiceControl,
    meta: {
      screen_code: "04-004",
    },
  },
  {
    path: "/booking/service-control/:id",
    name: "ServiceControlEdit",
    component: ServiceControl,
    meta: {
      screen_code: "04-004",
    },
  },


];