<template>
   <v-container fluid class="d-flex justify-center align-center backgroundW"
      style="height: 100vh;position: fixed; top: 0;left:0; width:100vw ; z-index: 1000;">

      <div>
         <!-- <AnimatedLogo height="160px" classes="justify-center d-flex" /> -->
         <AnimatedLoading classes="pt-2  pa-5" :isLoading="true" :height="250" :hideText="true" />
      </div>

   </v-container>
</template>
<style  scoped>

</style>


<script>
// import AnimatedLogo from './AnimatedLogo.vue';
import AnimatedLoading from './AnimatedLoading.vue';

export default {
   name: "SplashLoader",
   computed: {

   },
   components: {
      // AnimatedLogo,
      AnimatedLoading
   },
   data: () => ({

   }),
   mounted() {

   },

   methods: {

   },
};
</script>
