<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row justify="space-between">

                  <v-col cols="12">
                     <div class="subtitle-1">{{ $t('logo') }}</div>
                  </v-col>
                  <GenericInput type="file" :value="form.main_logo_ar" @input="form.main_logo_ar = $event"
                     label="صورة الشعار العربي" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>

                  <GenericInput type="file" dir="ltr" :value="form.main_logo_en" @input="form.main_logo_en = $event"
                     label="English Logo" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <GenericInput type="file" :value="form.footer_logo_ar" @input="form.footer_logo_ar = $event"
                     label="صورة الشعار السفلي" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>

                  <GenericInput type="file" dir="ltr" :value="form.footer_logo_en" @input="form.footer_logo_en = $event"
                     label="English Footer Logo" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 3]">
                  </GenericInput>
                  <v-col cols="12">
                     <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12">
                     <div class="subtitle-1">{{ $t('social media data') }}</div>
                  </v-col>
                  <GenericInput type="mobile" :value="form.phone" @input="form.phone = $event" label="phone"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="email" :value="form.email" @input="form.email = $event" label="email"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.whatsapp" @input="form.whatsapp = $event" label="whatsapp"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.facebook_link" @input="form.facebook_link = $event"
                     label="facebook" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.linkedin_link" @input="form.linkedin_link = $event"
                     label="linkedin" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.twitter_link" @input="form.twitter_link = $event" label="twitter"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>

                  <GenericInput type="text" :value="form.youtube_link" @input="form.youtube_link = $event" label="youtube"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 4]">
                  </GenericInput>
                  <v-col cols="12">
                     <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12">
                     <div class="subtitle-1">{{ $t('static pages') }}</div>
                  </v-col>


                  <GenericInput type="textarea" :value="form.footer_desc_ar" @input="form.footer_desc_ar = $event"
                     label="مختصر السفلي" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="textarea" dir="ltr" :value="form.footer_desc_en"
                     @input="form.footer_desc_en = $event" label="footer brief" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.about_ar" @input="form.about_ar = $event" label="نبذة عنا"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.about_en" @input="form.about_en = $event"
                     label="About US" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.goal_ar" @input="form.goal_ar = $event"
                     label="اهداف" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.goal_en" @input="form.goal_en = $event"
                     label="Goals" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <!--  -->
                  <GenericInput type="texteditor" :value="form.mission_ar" @input="form.mission_ar = $event"
                     label="مهمتنا" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.mission_en" @input="form.mission_en = $event"
                     label="Mission" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.vision_ar" @input="form.vision_ar = $event"
                     label="رؤية" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.vision_en" @input="form.vision_en = $event"
                     label="Vision" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!-- xxxxxxx -->


                  <!--  -->
                  <GenericInput type="texteditor" :value="form.website_policy_ar"
                     @input="form.website_policy_ar = $event" label="سياسة الموقع" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.website_policy_en"
                     @input="form.website_policy_en = $event" label="website policy" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <!--  -->
                  <GenericInput type="texteditor" :value="form.delivery_info_ar"
                     @input="form.delivery_info_ar = $event" label="معلومات التوصيل" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.delivery_info_en"
                     @input="form.delivery_info_en = $event" label="Delivery info" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <!--  -->
                  <GenericInput type="texteditor" :value="form.gift_cards_policy_ar"
                     @input="form.gift_cards_policy_ar = $event" label="سياسة بطاقات الهدايا" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.gift_cards_policy_en"
                     @input="form.gift_cards_policy_en = $event" label="Gift Card Policy" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.delivery_info_ar"
                     @input="form.delivery_info_ar = $event" label="معلومات التوصيل" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.delivery_info_en"
                     @input="form.delivery_info_en = $event" label="Delivery info" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <!--  -->

                  <!--  -->
                  <GenericInput type="texteditor" :value="form.personal_privacy_ar"
                     @input="form.personal_privacy_ar = $event" label="خصوصية الشخصيه" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.personal_privacy_en"
                     @input="form.personal_privacy_en = $event" label="Personal privacy" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <!--  -->
                  <GenericInput type="texteditor" :value="form.security_ar" @input="form.security_ar = $event"
                     label="الأمن" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.security_en" @input="form.security_en = $event"
                     label="Security" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.access_options_ar" @input="form.access_options_ar = $event"
                     label="الدخول والتعديل والخيارات" :required="false" :isLoading="pageData.editIsLoading"
                     :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.access_options_en"
                     @input="form.access_options_en = $event" label="Access, modification and options" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.third_party_websites_ar"
                     @input="form.third_party_websites_ar = $event" label="مواقع الطرف الثالث" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.third_party_websites_en"
                     @input="form.third_party_websites_en = $event" label="Third party websites" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.information_period_ar"
                     @input="form.information_period_ar = $event" label="فتره الاحتفاظ بالمعلومات" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.information_period_en"
                     @input="form.information_period_en = $event" label="Information retention period" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.sensitive_information_ar"
                     @input="form.sensitive_information_ar = $event" label="المعلومات الحساسة" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
                  <GenericInput type="texteditor" dir="ltr" :value="form.sensitive_information_en"
                     @input="form.sensitive_information_en = $event" label="Sensitive information" :required="false"
                     :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>
   
                  <!--  -->
                  <GenericInput type="texteditor" :value="form.contact_ar" @input="form.contact_ar = $event" label="اتصلوا بنا"
                     :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.contact_en" @input="form.contact_en = $event"
                     label="Contact" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 12, 6]">
                  </GenericInput>


               </v-row>


            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
export default {
   name: "AdsControl",
   data: () => ({
      pageData: {
         screen_code: "03-001",
         url: null,
         controlRoute: "website/website_basic_data",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      users: [],
      transactions: [],
      form: {
         main_logo_ar: null,
         main_logo_en: null,
         footer_logo_ar: null,
         footer_logo_en: null,
         footer_desc_en: null,
         footer_desc_ar: null,
         about_ar: null,
         about_en: null,
         mission_ar: null,
         mission_en: null,
         vision_ar: null,
         vision_en: null,
         goal_ar: null,
         goal_en: null,
         phone: null,
         email: null,
         whatsapp: null,
         facebook_link: null,
         linkedin_link: null,
         twitter_link: null,
         youtube_link: null,
         // 
         personal_privacy_en: null,
         personal_privacy_ar: null,
         access_options_en: null,
         access_options_ar: null,
         security_en: null,
         security_ar: null,
         third_party_websites_en: null,
         third_party_websites_ar: null,
         information_period_en: null,
         information_period_ar: null,
         sensitive_information_en: null,
         sensitive_information_ar: null,
         privacy_policy_en: null,
         privacy_policy_ar: null,
         contact_ar: null,
         contact_en: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`website_basic_data`).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.form = response.data.items[0]
               }
            })
         } else {
            this.$router.push('/')
         }
      },
      save() {
         if (this.$refs.form.validate()) {
            this.form._method = 'PUT';
            this.pageData.isLoading = true;
            this.$api.POST_METHOD(`website_basic_data/1`, this.form).then((response) => {
               this.pageData.isLoading = false;
               if (response.check) {
                  this.getData()
               }
            })
         }
      },

      deleteMethod() {

      },


   },
};
</script>
