<template>
   <section>
      <v-container fluid>
         <v-form ref="form" v-model="valid" class="pa-3 mt-n6 px-0 ">
            <v-card class="backgroundW shadow overflow-hidden pa-0 rounded-b-xl rounded-t-0 pt-3">
               <v-sheet class="pa-5">
                  <v-row>
                     <v-col cols="6">
                        <v-btn depressed color="success" @click="openDialog = true">
                           <v-icon>mdi-plus</v-icon>
                           {{ $t("add options") }}
                        </v-btn>
                     </v-col>
                     <v-col cols="6" class="text-left" v-if="pageData.rows.length">
                        <v-btn depressed color="info" :loading="saveLoading" @click="save" :disabled="!valid">
                           {{ $t("save") }}
                        </v-btn>
                     </v-col>

                     <v-col cols="12">
                        <v-form ref="form" v-model="valid">
                           <DynamicTable :isLoading="pageData.isLoading" :data="pageData.rows"
                              :header="pageData.tableHeader" :option="pageData.options" :pageData="pageData"
                              :editValueMethod="edit">
                              <template v-slot:td="{ row, header, index }">
                                 <v-sheet>
                                    <div v-if="header.text == 'shipping weight'">
                                       <GenericInput type="text" :value="row.weight" :hideDetails="true"
                                          @input="pageData.rows[index].weight = $event" :multi="false" :required="false"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                       </GenericInput>
                                    </div>
                                    <div v-if="header.text == 'quantity'">
                                       <GenericInput type="float" :value="row.quantity" :hideDetails="true"
                                          @input="pageData.rows[index].quantity = $event" :required="false"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                       </GenericInput>
                                    </div>
                                    <div v-if="header.text == 'SKU'">
                                       <GenericInput type="text" :value="row.sku" :hideDetails="true" :disabled="false"
                                          @input="pageData.rows[index].sku = $event" :required="false"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                       </GenericInput>
                                    </div>
                                    <div v-if="header.text == 'cost price'">
                                       <GenericInput type="float" :value="row.cost_price" :hideDetails="true"
                                          @input="pageData.rows[index].cost_price = $event" :required="false"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                       </GenericInput>
                                    </div>
                                    <div v-if="header.text == 'the price'">
                                       <GenericInput type="float" :value="row.price" :hideDetails="true"
                                          @input="pageData.rows[index].price = $event" :required="true"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]">
                                       </GenericInput>
                                    </div>
                                    <div v-if="header.text == 'status'">
                                       <GenericInput type="switch" :value="row.is_publish"
                                          @input="pageData.rows[index].is_publish = $event" label="publish" :multi="false"
                                          :required="false" selected_label="name" selected_prop="id"
                                          :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                                       </GenericInput>
                                    </div>
                                 </v-sheet>
                              </template>
                           </DynamicTable>
                        </v-form>
                     </v-col>
                     <!-- End of Form -->
                  </v-row>
               </v-sheet>
            </v-card>
         </v-form>
      </v-container>

      <ProductOptionsDialog :openDialog="openDialog" :closeDialog="() => openDialog = false"
         :callBackMethod="callBackMethod" :refreshData="getData" />
   </section>
</template>



<script>
import DynamicTable from '@/components/DynamicTable.vue';
import GenericInput from '@/components/ui/GenericInput.vue';
import ProductOptionsDialog from './ProductOptionsDialog.vue';

export default {
   name: "ProductAttributes",

   data: () => ({
      pageData: {
         screen_code: "02-004",
         url: null,
         controlRoute: "/code/product-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         rows: [],
         tableHeader: [],
         options: {},
      },
      valid: false,
      openDialog: false,
      saveLoading: false,
      receiveProductOption: {},
      stock_availability: [],
      selectedRow: [],
      newRows: [],
      tempData: [],
   }),

   components: {
      ProductOptionsDialog,
      DynamicTable,
      GenericInput,
   },
   props: {
      SaveValidation: { type: Function },
   },
   methods: {
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`product_stock/${this.$route.params.id}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.pageData.rows = response.data.stock
                     console.log("pageData.rows", this.pageData.rows);
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
      },
      save() {
         this.saveLoading = true;
         this.$api.POST_METHOD(`product/${this.$route.params.id}`, this.pageData.rows).then((response) => {
            this.saveLoading = false;
            if (response.check) {
               console.log("save response ", response);
            }
         })
      },
      edit(row) {
         console.log("edit", row);
      },
      pageMainData() {
         this.pageData.tableHeader = [
            { text: "variant", key: "name", type: 'text', width: 250, classes: "" },
            { text: "the price", key: "price", type: 'slot', classes: "" },
            { text: "cost price", key: "cost_price", type: 'slot', classes: "" },
            { text: "SKU", key: "sku", type: 'slot', classes: "" },
            { text: "quantity", key: "quantity", type: 'slot', classes: "" },
            { text: "shipping weight", key: "weight", type: 'slot', classes: "" },
            { text: "status", key: "is_publish", type: 'slot', classes: "" },
            // { text: "actions", key: "id", type: 'actions', classes: "" },
         ];
         this.stock_availability = [
            {
               id: 0,
               name: this.$i18n.t("out stock")
            },
            {
               id: 1,
               name: this.$i18n.t("in stock")
            }
         ]
      }

   },
   computed: {

   },
   mounted() {
      this.pageMainData();
      this.getData();
   }
}
</script>
