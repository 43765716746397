<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3">
            <v-card class="backgroundW shadow overflow-hidden pa-0">
               <v-sheet class="pa-5">
                  <v-row align="center">
                     <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                     <GenericInput type="text" dir="ltr" :value="form.name_en" @input="form.name_en = $event"
                        label="name_en" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="switch" :value="form.is_color" @input="form.is_color = $event" label="color menu"
                        :multi="false" :required="false" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <v-col cols="12">
                        <h4 class="pa-3">{{ $t('variant details') }}</h4>
                     </v-col>


                     <v-col cols="12" :key="refreshCounter">
                        <v-row class="px-5 py-3" align="center" v-for="(variantDetails, i) in form.details" :key="i">
                           <GenericInput type="text" :value="variantDetails.name_ar"
                              @input="variantDetails.name_ar = $event" label="name_ar" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                           </GenericInput>

                           <GenericInput type="text" dir="ltr" :value="variantDetails.name_en"
                              @input="variantDetails.name_en = $event" label="name_en" :required="true"
                              :isLoading="pageData.editIsLoading" :cols="[12, 5, 5]">
                           </GenericInput>

                           <v-col cols="12" md="auto" lg="auto" class="d-flex align-center pa-0" v-if="form.is_color">
                              <label for="color" class="px-1 font-weight-medium">{{ $t('the color') }}</label>
                              <input type="color" id="color" @input="variantDetails.color_code = $event.target.value"
                                 :value="variantDetails.color_code">
                           </v-col>

                           <v-col cols="12" md="auto" lg="auto">
                              <v-icon @click="removeVariant(i)" color="error">mdi-close-circle-outline</v-icon>
                           </v-col>
                        </v-row>
                     </v-col>

                     <v-col cols="12">
                        <v-btn @click="addVariant" width="100%" class="pa-5" color="accent" depressed>
                           <v-icon color="white" class="px-1">mdi-file-plus-outline</v-icon>
                           {{ $t('add variant') }}
                        </v-btn>
                     </v-col>
                  </v-row>

               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>
   </section>
</template>



<script>
import ControlHeader from "@/components/ui/ControlHeader.vue";
import GenericInput from '@/components/ui/GenericInput.vue';

export default {
   name: "VariantControl",

   data: () => ({
      pageData: {
         screen_code: "02-017",
         url: null,
         controlRoute: "code/variants-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      refreshCounter: 0,
      departments: [],
      form: {
         name_en: null,
         name_ar: null,
         is_color: null,
         details: [
            {
               name_en: null,
               name_ar: null,
               color_code: null,
            }
         ],
      },
   }),
   components: {
      GenericInput,
      ControlHeader,
   },
   computed: {

   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`variant/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = { ...response.data.master };
                     this.form.details = [...response.data.details];
                     // this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`attribute/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response)
                  }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`variant/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`variant`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      addVariant() {
         this.refreshCounter++
         if (this.pageData.isEdit) {
            this.form.details.push({
               variant_d_id: 0,
               name_en: null,
               name_ar: null,
               color_code: null,
            })
         } else {
            this.form.details.push({
               name_en: null,
               name_ar: null,
               color_code: null,
            })
         }
      },
      removeVariant(index) {
         this.refreshCounter++
         this.form.details.splice(index, 1)
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`variant/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>

<style lang="scss" scoped>
#color {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   width: 53px;
   height: 33px;
   background-color: transparent;
   border: none;
   cursor: pointer;
}

#color::-webkit-color-swatch {
   border-radius: 15px;
   border: none;
}
</style>
