<template>
  <v-app v-resize="onResize">
    <SplashLoader v-if="$store.state.splashScreen == true"></SplashLoader>
    <router-view></router-view>
    <SpeedTest v-if="$store.state.splashScreen == false" />
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
<script>
import SplashLoader from "@/components/ui/SplashLoader.vue";
import SpeedTest from "@/components/SpeedTest.vue";

export default {
  name: "App",
  components: { SplashLoader, SpeedTest },
  data: () => ({
    isLoading: false,
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  mounted() {
    this.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
   this.$store.state.Settings.custom_theme.side_bar = localStorage.getItem('appBarNavigation') == 'true' ? true  : false;
    this.$store.dispatch("checkRoute");
    // document.addEventListener('swReady', this.swReady);
  },
  watch: {
    $route() {
      this.$store.dispatch("checkRoute");
    }
  },
  created() {
    // document.addEventListener('swUpdated', this.swUpdated, { once: true });
    document.addEventListener('swUpdateFound', this.swUpdateFound, { once: true });
    // document.addEventListener('swOffline', this.swOffline, { once: true });
    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   if (this.refreshing) return
    //   this.refreshing = true
    //   window.location.reload()
    // })
  },
  methods: {
    swUpdated(event) {
      console.log('event', event);
      this.registration = event.detail
      // this.$global.ShowAlert('info', this.$i18n.t('New update is available, this page will refresh'));
      this.refreshApp()
    },
    swUpdateFound(event) {
      console.log('swUpdateFound', event);
      // this.$global.ShowAlert('info', this.$i18n.t('New update is downloading'));
      this.$router.push('/system_update')
    },
    swOffline(event) {
      console.log('swOffline', event);
      this.$store.state.networkConnection = false;
      this.$global.ShowAlert('info', this.$i18n.t('No Internet Connection'));
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.$router.push('/')
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onResize() {
      this.$store.state.Settings.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
};
</script>
