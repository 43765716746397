<template>
   <section>
      <v-container fluid>
         <!-- Header -->
         <ControlHeader :pageData="pageData" :form="form" :save="save" :valid="valid" :deleteMethod="deleteMethod" />
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-5">
            <v-card class="backgroundW shadow pa-5 pt-7">
               <v-row align="center">

                  <GenericInput type="text" :value="form.title_ar" @input="form.title_ar = $event"
                     label="title in arabic" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="text" dir="ltr" :value="form.title_en" @input="form.title_en = $event"
                     label="title in english" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
                     label="description_ar" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="texteditor" dir="ltr" :value="form.description_en"
                     @input="form.description_en = $event" label="description_en" :required="true"
                     :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="dropzone" :value="form.image_ar" @input="form.image_ar = $event"
                     label="arabic picture" :multi="false" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]">
                  </GenericInput>

                  <GenericInput type="dropzone" dir="ltr" :value="form.image_en" @input="form.image_en = $event"
                     label="english image" :multi="false" :required="true" :isLoading="pageData.editIsLoading"
                     :cols="[12, 6, 6]">
                  </GenericInput>
               </v-row>

               <v-row class="comments-section pa-3" v-if="!pageData.isLoading">
                  <v-col cols="12">
                     <h4>{{ $t("users comments") }}</h4>
                  </v-col>
                  <v-card class="mx-auto my-5" width="100%" outlined v-for="comment in blog_comments" :key="comment.id">
                     <v-list-item three-line>
                        <v-avatar size="56">
                           <lazy-image :height="80" :src="$api.serverUrl + comment.user_image"
                              :error="require('@/assets/img/svg/default_profile.svg')"
                              :loading="require('@/assets/img/svg/default_profile.svg')" />
                        </v-avatar>

                        <v-list-item-content class="pa-2">
                           <div class="d-flex justify-space-between">
                              <span class="text-button mb-4">
                                 {{ $t("username") }}: {{ comment.user_full_name }}
                              </span>
                              <span class="text-button mb-4">
                                 {{ $t("date") }}: {{ comment.add_date }}
                              </span>
                           </div>
                           <v-list-item-subtitle>{{ comment.comment }}</v-list-item-subtitle>
                        </v-list-item-content>
                     </v-list-item>
                  </v-card>
               </v-row>

            </v-card>
         </v-form>
         <!-- End of Form -->


      </v-container>
   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';
import ControlHeader from "@/components/ui/ControlHeader.vue";
import LazyImage from '@/components/ui/LazyImage.vue';
export default {
   name: "BlogsControl",
   data: () => ({
      pageData: {
         screen_code: "03-003",
         url: null,
         controlRoute: "website/blog-control",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      users: [],
      transactions: [],
      blog_comments: [],
      form: {
         title_ar: null,
         title_en: null,
         description_ar: null,
         description_en: null,
         image_en: null,
         image_ar: null,
      }
   }),
   components: {
      ControlHeader,
      GenericInput,
      LazyImage,
   },
   computed: {
   },
   mounted() {
      this.pageMainData();
      this.formatDate();
      this.getData();
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title : this.$i18n.t("add") + " " + this.$global.FilterPermissions(this.pageData.screen_code).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`blog/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);
                     this.form = response.data.data;
                     this.blog_comments = response.data.blog_comments;
                     console.log("this.blog_comments", this.blog_comments);
                     this.$refs.form.validate();
                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {

               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`blog/create`).then(() => {
                  this.pageData.isLoading = false;
                  // if (response.check) {


                  // }
               })
            } else {
               this.$router.push('/')
            }

         }

      },
      save() {
         if (this.$refs.form.validate()) {
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`blog/${this.$route.params.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`blog`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      formatDate() {
         var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2)
            month = '0' + month;
         if (day.length < 2)
            day = '0' + day;
         this.form.start_date = [year, month, day].join('-');
         this.form.end_date = [year, month, day].join('-');
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`blog/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
};
</script>
<style lang="scss" scoped>
* {
   letter-spacing: 0 !important;
}
</style>
