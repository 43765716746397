// Website Module Routes:-

import AdsEntity from "@/views/Dashboard/WebsiteModule/Ads/AdsEntity.vue";
import AdsControl from "@/views/Dashboard/WebsiteModule/Ads/AdsControl.vue";

import StaticPagesEntity from "@/views/Dashboard/WebsiteModule/StaticPages/StaticPagesEntity.vue";
import StaticPagesControl from "@/views/Dashboard/WebsiteModule/StaticPages/StaticPagesControl.vue";
import SlidersEntity from "@/views/Dashboard/WebsiteModule/Sliders/SlidersEntity.vue";
import SlidersControl from "@/views/Dashboard/WebsiteModule/Sliders/SlidersControl.vue";
import ContactUsEntity from "@/views/Dashboard/WebsiteModule/ContactUs/ContactUsEntity.vue";
import ContactUsControl from "@/views/Dashboard/WebsiteModule/ContactUs/ContactUsControl.vue";
import WebsiteBasicsControl from "@/views/Dashboard/WebsiteModule/WebsiteBasics/WebsiteBasicsControl.vue";
import SectionsEntity from "@/views/Dashboard/WebsiteModule/Sections/SectionsEntity.vue";
import SectionsControl from "@/views/Dashboard/WebsiteModule/Sections/SectionsControl.vue";
import FeatureEntity from "@/views/Dashboard/WebsiteModule/Feature/FeatureEntity.vue";
import FeatureControl from "@/views/Dashboard/WebsiteModule/Feature/FeatureControl.vue";
import DemoRequestEntity from "../views/Dashboard/WebsiteModule/DemoRequest/DemoRequestEntity.vue";
import SEOControl from "../views/Dashboard/WebsiteModule/SEO/SEOControl.vue";
import SEOEntity from "../views/Dashboard/WebsiteModule/SEO/SEOEntity.vue";
import CommonQuestionEntity from "@/views/Dashboard/WebsiteModule/CommonQuestion/CommonQuestionEntity.vue";
import CommonQuestionControl from "@/views/Dashboard/WebsiteModule/CommonQuestion/CommonQuestionControl.vue";
import TestimonialEntity from "@/views/Dashboard/WebsiteModule/Testimonial/TestimonialEntity.vue";
import TestimonialControl from "@/views/Dashboard/WebsiteModule/Testimonial/TestimonialControl.vue";
import GoalEntity from "@/views/Dashboard/WebsiteModule/Goal/GoalEntity.vue";
import GoalControl from "@/views/Dashboard/WebsiteModule/Goal/GoalControl.vue";
import ImageEntity from "@/views/Dashboard/WebsiteModule/Image/ImageEntity.vue";
import BlogsEntity from "@/views/Dashboard/WebsiteModule/Blogs/BlogsEntity.vue";
import BlogsControl from "@/views/Dashboard/WebsiteModule/Blogs/BlogsControl.vue";

export const WebsiteModuleRoutes = [
  // Ads
  {
    path: "/website/ads",
    name: "AdsEntity",
    component: AdsEntity,
    meta: {
      screen_code: "03-002",
    },
  },
  {
    path: "/website/ads-control",
    name: "AdsControl",
    component: AdsControl,
    meta: {
      screen_code: "03-002",
    },
  },
  {
    path: "/website/ads-control/:id",
    name: "AdsControlEdit",
    component: AdsControl,
    meta: {
      screen_code: "03-002",
    },
  },

  // Static Pages
  {
    path: "/website/static_page",
    name: "StaticPagesEntity",
    component: StaticPagesEntity,
    meta: {
      screen_code: "03-012",
    },
  },
  {
    path: "/website/static_page-control",
    name: "StaticPagesControl",
    component: StaticPagesControl,
    meta: {
      screen_code: "03-012",
    },
  },
  {
    path: "/website/static_page-control/:id",
    name: "StaticPagesControlEdit",
    component: StaticPagesControl,
    meta: {
      screen_code: "03-012",
    },
  },
  // Sliders
  {
    path: "/website/slider",
    name: "SlidersEntity",
    component: SlidersEntity,
    meta: {
      screen_code: "03-011",
    },
  },
  {
    path: "/website/slider-control",
    name: "SlidersControl",
    component: SlidersControl,
    meta: {
      screen_code: "03-011",
    },
  },
  {
    path: "/website/slider-control/:id",
    name: "SlidersControlEdit",
    component: SlidersControl,
    meta: {
      screen_code: "03-011",
    },
  },
  // WebsiteBasics
  {
    path: "/website/basic_data",
    name: "WebsiteBasicsControl",
    component: WebsiteBasicsControl,
    meta: {
      screen_code: "03-001",
    },
  },
  // Contact Us
  {
    path: "/website/contact_us",
    name: "ContactUsEntity",
    component: ContactUsEntity,
    meta: {
      screen_code: "03-005",
    },
  },
  {
    path: "/website/contact_us-control",
    name: "ContactUsControl",
    component: ContactUsControl,
    meta: {
      screen_code: "03-005",
    },
  },
  {
    path: "/website/contact_us-control/:id",
    name: "ContactUsControlEdit",
    component: ContactUsControl,
    meta: {
      screen_code: "03-005",
    },
  },
  // Sections
  {
    path: "/website/section",
    name: "SectionsEntity",
    component: SectionsEntity,
    meta: {
      screen_code: "03-009",
    },
  },
  {
    path: "/website/section-control",
    name: "SectionsControl",
    component: SectionsControl,
    meta: {
      screen_code: "03-009",
    },
  },
  {
    path: "/website/section-control/:id",
    name: "SectionsControlEdit",
    component: SectionsControl,
    meta: {
      screen_code: "03-009",
    },
  },
  // Feature
  {
    path: "/website/feature",
    name: "FeatureEntity",
    component: FeatureEntity,
    meta: {
      screen_code: "03-014",
    },
  },
  {
    path: "/website/feature-control",
    name: "FeatureControl",
    component: FeatureControl,
    meta: {
      screen_code: "03-014",
    },
  },
  {
    path: "/website/feature-control/:id",
    name: "FeatureControlEdit",
    component: FeatureControl,
    meta: {
      screen_code: "03-014",
    },
  },
  // Demo request
  {
    path: "/website/demo_request",
    name: "DemoRequestEntity",
    component: DemoRequestEntity,
    meta: {
      screen_code: "02-010",
    },
  },
  {
    path: "/website/seo",
    name: "SEOEntity",
    component: SEOEntity,
    meta: {
      screen_code: "03-010",
    },
  },
  {
    path: "/website/seo-control",
    name: "SEOControl",
    component: SEOControl,
    meta: {
      screen_code: "03-010",
    },
  },
  {
    path: "/website/seo-control/:id",
    name: "SEOControlEdit",
    component: SEOControl,
    meta: {
      screen_code: "03-010",
    },
  },

  // CommonQuestion
  {
    path: "/website/common_question",
    name: "CommonQuestionEntity",
    component: CommonQuestionEntity,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/website/common_question-control",
    name: "CommonQuestionControlControl",
    component: CommonQuestionControl,
    meta: {
      screen_code: "03-004",
    },
  },
  {
    path: "/website/common_question-control/:id",
    name: "CommonQuestionControlEdit",
    component: CommonQuestionControl,
    meta: {
      screen_code: "03-004",
    },
  },
  // Testimonial
  {
    path: "/website/testimonial",
    name: "TestimonialEntity",
    component: TestimonialEntity,
    meta: {
      screen_code: "03-013",
    },
  },
  {
    path: "/website/testimonial-control",
    name: "TestimonialControlControl",
    component: TestimonialControl,
    meta: {
      screen_code: "03-013",
    },
  },
  {
    path: "/website/testimonial-control/:id",
    name: "TestimonialControlEdit",
    component: TestimonialControl,
    meta: {
      screen_code: "03-013",
    },
  },
  // Goal
  {
    path: "/website/Goal",
    name: "GoalEntity",
    component: GoalEntity,
    meta: {
      screen_code: "03-006",
    },
  },
  {
    path: "/website/Goal-control",
    name: "GoalControlControl",
    component: GoalControl,
    meta: {
      screen_code: "03-006",
    },
  },
  {
    path: "/website/Goal-control/:id",
    name: "GoalControlEdit",
    component: GoalControl,
    meta: {
      screen_code: "03-006",
    },
  },
  // Image
  {
    path: "/website/image",
    name: "ImageEntity",
    component: ImageEntity,
    meta: {
      screen_code: "03-007",
    },
  },
  // Blogs
  {
    path: "/website/blog",
    name: "BlogsEntity",
    component: BlogsEntity,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/website/blog-control",
    name: "BlogsControlControl",
    component: BlogsControl,
    meta: {
      screen_code: "03-003",
    },
  },
  {
    path: "/website/blog-control/:id",
    name: "BlogsControlEdit",
    component: BlogsControl,
    meta: {
      screen_code: "03-003",
    },
  },

];