<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center">
        <v-col cols="auto">
          <div class="text-h1 primary--text text-center my-3">
            {{ 404 }}
          </div>
          <div class="text-h4 my-5">{{ $t('Page not Found') }}</div>
          <v-btn color="primary" x-large @click="$router.go(-1)" text class="mt-2 d-block mx-auto" elevation="0">
            {{ $t('Back previous page')}}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  //background-image: url("../../assets/img/svg/errorBg.svg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  img {
    margin: auto;
    display: block;
    max-height: 200px;
  }
}
</style>

<script>
export default {
  name: "Error404",
  computed: {},
};
</script>