<template>
   <v-container fluid id="sidebar-container">
      <v-row :justify="$store.state.sidebarIsOpen ? 'center' : 'end'">
         <v-col cols="auto" class="pa-5 pb-0 mt-2">
            <v-btn small @click.stop="toggleSidebar" :class="$vuetify.rtl == true ? '' : 'rotate-180'" fab
               color="primary" elevation="0" height="26" width="26">
               <v-icon color="white" v-if="!$store.state.sidebarIsOpen" size="20">mdi-chevron-right</v-icon>
               <v-icon color="white" v-if="$store.state.sidebarIsOpen" size="20">mdi-chevron-left</v-icon>
            </v-btn>
         </v-col>
         <v-col cols="12" class="mt-n3">
            <router-link to="/">
               <v-fade-transition>
                  <img v-if="!$store.state.sidebarIsOpen" src="@/assets/logo_ar.svg" style="max-height: 100px;"
                     class="d-block ma-auto" alt="the concept" />
                  <img v-if="$store.state.sidebarIsOpen" src="@/assets/img/png/icon.png" style="max-height: 30px;"
                     class="d-block ma-auto mt-4" alt="the concept" />
               </v-fade-transition>

            </router-link>
         </v-col>
      </v-row>
      <v-list dense class="mt-3 px-0 overflow-auto" :max-height="$store.state.Settings.windowSize.y - 150">
         <!-- List Level 1 link -->
         <!-- Dashboard -->
         <span class="d-block text-center">
            <v-list-item active-class="activeItem" link to="/" color="primary" class="my-1">
               <v-list-item-icon>
                  <img src="@/assets/icon.png" alt="the concept" style="height: 25px; width : 25px"
                     :class="$store.state.sidebarIsOpen ? '' : 'mx-n1'" />
               </v-list-item-icon>
               <v-list-item-title class="text-start">{{ $t('dashboard') | capitalize }}</v-list-item-title>
            </v-list-item>
         </span>
         <span v-for="(list, i) in menuList" :key="i" class="d-block text-center">

            <!-- List Level 1 group -->
            <v-list-group :ripple="false" color="primary" class="pa-1" active-class="activeLink"
               :value="$store.state.activeScreen.main_id == list.id">
               <template v-slot:activator>
                  <v-list-item-icon v-lazy-container="{ selector: 'img' }">

                     <img :data-src="$api.serverUrl + list.image" :alt="list.title"
                        :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'" style="height: 20px; width : 20px" />
                  </v-list-item-icon>
                  <v-list-item-title>{{ list.title | capitalize }}</v-list-item-title>
               </template>

               <!-- List Level 2 list-->

               <span v-for="(sub, y) in list.subs" :key="y" class="d-block my-0 text-center">
                  <v-list-item active-class="activeLink" link :to="sub.url">
                     <!-- <img v-if="$store.state.activeScreenCode == sub.screen_code"
                     src="@/assets/img/svg_icons/screen_icon.svg" class="mx-1" alt="the concept">
                  <img v-else src="@/assets/img/svg_icons/screen_icon.svg" class="mx-1" alt="the concept"> -->
                     <v-list-item-title>{{ sub.sub_title | capitalize }}</v-list-item-title>
                  </v-list-item>
               </span>
            </v-list-group>
         </span>
      </v-list>
   </v-container>
</template>

<script>
export default {
   name: "Sidebar",
   mounted() {
      this.menuList = this.$store.state.screens;
      // console.log(this.menuList);
   },
   data: () => ({
      menuList: []
   }),
   methods: {
      toggleSidebar() {
         this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
      },
   }
};
</script>


<style scoped lang="scss">
.activeLink {
   opacity: 1 !important;
   font-weight: 500 !important;

   color: #212121;

   img:first-of-type {
      display: none;
   }

   img:last-of-type {
      display: block;
   }
}

span {
   img {
      width: 20px;
      height: 20px;
   }

   // img:last-of-type {
   //    display: none;
   // }
}

.v-list-item,
.v-list-item--link,
.v-list-item--active {
   // border-radius: 10px !important;
}

.theme--light .activeLink {
   background: transparent !important;
   color: #bf9d5f;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--light.v-application--is-rtl .activeLink {
   background: transparent !important;
   color: #bf9d5f;

   .v-list-item__title {
      font-weight: bold !important;
   }
}

.theme--dark .activeLink {
   border-left: 4px solid #bf9d5f;
   border-right: 0px solid #bf9d5f;
}

.theme--dark.v-application--is-rtl .activeLink {
   border-left: 0 solid #bf9d5f;
   border-right: 4px solid #bf9d5f;
   color: #bf9d5f;
}

.v-list-item img {
   filter: grayscale(100%) !important;
}

.v-list-item--active img {
   filter: grayscale(0%) !important;
}

.v-application--is-ltr .v-list-item__icon:first-child {
   margin-right: 12px !important;
}

.v-application--is-ltr .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-list-group .v-list-item__title {
   text-align: initial;
   font-size: 12px;
}

.v-list-group .v-list-item--link {
   text-align: initial;
   padding: 0 2.5rem;
}

.v-list-item--link {
   opacity: 0.7;
}

.v-application--is-rtl .v-navigation-drawer--mini-variant .v-list-item__icon:first-child {
   margin-right: 0 !important;
}

.v-application--is-rtl .v-list-item__icon:first-child {
   margin-left: 0;
   margin-right: 0;
}

.v-divider {
   opacity: 0.65 !important;
   width: 200px !important;
}

.theme--light {
   .v-list-group--active {
      background: #fffcee !important;
      border-radius: 9px !important;
   }
}

.theme--dark {
   .v-list-item--link {
      opacity: 1;
   }

   .v-list-group--active {
      background: #191919 !important;
      border-radius: 9px !important;

      .v-list-item__title {
         color: #fff !important
      }
   }

   .activeItem .v-list-item__title {
      color: #fff !important
   }
}

.theme--dark {
   .activeItem {
      background: #191919 !important;
      color: #191919 !important
   }
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
   opacity: 0;
}

.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-list-item__title {
   font-weight: bold;
   color: #bf9d5f !important;
}


.v-list-group--sub-group .v-list-group__header.v-list-item--active .v-icon {
   color: #bf9d5f !important;
   transform: rotate(180deg);
}

.activeItem {
   background: #fffcee !important;
   border-radius: 9px !important;

   .v-list-item__title {
      font-weight: bold !important;
      color: #333333 !important;
   }
}
</style>