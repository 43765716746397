<template>
   <section>
      <v-container fluid>

         <!-- Form -->
         <v-form ref="form" v-model="valid" class="pa-3  px-0 " :class="{ 'mt-n6': pageData.isEdit }">
            <v-card class="backgroundW shadow  pt-3"
               :class="{ 'overflow-hidden pa-0 rounded-b-xl rounded-t-0': pageData.isEdit }">
               <v-sheet class="pa-5">

                  <!-- Product Inputs -->
                  <v-row align="center" v-if="tab === 0">
                     <GenericInput type="text" :value="form.name_ar" @input="form.name_ar = $event" label="name_ar"
                        :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]"></GenericInput>

                     <GenericInput type="text" dir="ltr" :value="form.name_en" @input="form.name_en = $event"
                        label="name_en" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="select" :lookups="filterDepartments" :value="form.departments"
                        @input="form.departments = $event" label="departments" :multi="true" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="filterCategorys" :value="form.categorys"
                        @input="form.categorys = $event" label="categories" :multi="true" :required="true"
                        selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="filterSubCategorys" :value="form.sub_categorys"
                        @input="form.sub_categorys = $event" label="sub categories" :multi="true" :required="true"
                        selected_label="name" selected_prop="id" :clearable="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 3, 3]">
                     </GenericInput>

                     <GenericInput type="select" :lookups="brands" :value="form.brand_id" @input="form.brand_id = $event"
                        label="branding" :multi="false" :required="true" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 3, 3]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="select" :lookups="available_statuses" :value="form.available_status_id"
                        @input="form.available_status_id = $event" label="available in the warehouse?" :multi="false"
                        :required="true" selected_label="name" selected_prop="id" :isLoading="pageData.editIsLoading"
                        :cols="[12, 4, 4]">
                     </GenericInput>

                     <GenericInput type="float" v-if="form.available_status_id == 1" :value="form.warehouse_stock"
                        @input="form.warehouse_stock = $event" label="quantity" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="float" :value="form.old_price" @input="form.old_price = $event"
                        label="product price" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <GenericInput type="float" :value="form.discount_value" @input="form.discount_value = $event"
                        label="discount value" :required="false" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <GenericInput type="float" :value="form.old_price - form.discount_value"
                        label="the final price of the product" :required="false" :disabled="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="switch" :value="form.is_publish" @input="form.is_publish = $event" label="publish"
                        :multi="false" :required="false" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <GenericInput type="switch" :value="form.must_attribute" @input="form.must_attribute = $event"
                        label="must attribute" :multi="false" :required="false" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <GenericInput type="switch" :value="form.price_in_attribute" @input="form.price_in_attribute = $event"
                        label="price in attribute" :multi="false" :required="false" selected_label="name"
                        selected_prop="id" :isLoading="pageData.editIsLoading" :cols="[12, 4, 4]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="switch" :value="form.is_package" @input="form.is_package = $event"
                        label="is package" :multi="false" :required="false" selected_label="name" selected_prop="id"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="select" v-if="form.is_package" :lookups="products" :value="form.package_products"
                        @input="form.package_products = $event" label="products" :multi="true" :required="false"
                        selected_label="name" selected_prop="id" :hide-details="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 6, 6]">
                     </GenericInput>

                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>

                     <GenericInput type="texteditor" :value="form.description_ar" @input="form.description_ar = $event"
                        label="description_ar" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="texteditor" dir="ltr" :value="form.description_en"
                        @input="form.description_en = $event" label="description_en" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="texteditor" :value="form.additional_data_ar"
                        @input="form.additional_data_ar = $event" label="additional data" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="texteditor" dir="ltr" :value="form.additional_data_en"
                        @input="form.additional_data_en = $event" label="Additional Data English" :required="true"
                        :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="dropzone" :value="form.image_ar" @input="form.image_ar = $event" label="image_ar"
                        :multi="false" :required="true" :isLoading="pageData.editIsLoading" :cols="[12, 6, 6]">
                     </GenericInput>

                     <GenericInput type="dropzone" dir="ltr" :value="form.image_en" @input="form.image_en = $event"
                        label="image_en" :multi="false" :required="true" :isLoading="pageData.editIsLoading"
                        :cols="[12, 6, 6]">
                     </GenericInput>

                  </v-row>
                  <!-- End Product Inputs -->
               </v-sheet>
            </v-card>
         </v-form>
         <!-- End of Form -->
      </v-container>

   </section>
</template>



<script>
import GenericInput from '@/components/ui/GenericInput.vue';


export default {
   name: "ProductData",

   data: () => ({
      pageData: {
         screen_code: "02-004",
         url: null,
         controlRoute: "/code/product-control/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      categories: [],
      categories_filter: [],
      sub_categories: [],
      sub_categories_filter: [],
      available_statuses: [],
      brands: [],
      departments: [],
      products: [],
      is_package: [],
      tab: 0,
      form: {
         name_en: null,
         name_ar: null,
         image_ar: null,
         image_en: null,
         categorys: [],
         sub_categorys: [],
         available_status_id: null,
         brand_id: null,
         departments: [],
         package_products: null,
         description_ar: null,
         description_en: null,
         additional_data_ar: null,
         additional_data_en: null,
         old_price: null,
         discount_value: null,
         is_package: null,
         warehouse_stock: null,
         is_publish: 1,
         must_attribute: null,
         price_in_attribute: null,
      },
   }),
   components: {
      GenericInput
   },
   props: {
      SaveValidation: { type: Function },
      returnPageData: { type: Function },
      resetHandelSave: { type: Function },
      handelSave: { type: Boolean }
   },
   mounted() {
      this.pageMainData()
      this.getData()
   },
   watch: {
      handelSave() {
         if (this.handelSave) {
            this.save()
         }
      }
   },
   computed: {
      filterDepartments() {
         if (this.form.categorys.length) {
            return this.departments.filter((department) => this.form.categorys.some(category => department.id === category));
         } else {
            return this.departments;
         }
      },
      filterCategorys() {
         if (this.form.departments.length) {
            return this.categories.filter(category => this.form.departments.some(department => category.department_id === department));
         } else if (this.form.sub_categorys.length) {
            return this.categories.filter(category => this.sub_categories.some(department => department.category_id === category.id));
         } else {
            return this.categories
         }

      },
      filterSubCategorys() {
         if (this.form.categorys.length) {
            return (
               this.sub_categories.filter(subCategory => this.form.categorys.some(category => subCategory.category_id === category))
            )
         } else {
            return this.sub_categories
         }
      },
      isValid() {
         return this.SaveValidation(this.valid && this.form.image_ar !== null && this.form.image_en !== null);
      }
   },
   created() {
      // this.SaveValidation(this.valid && this.validationImages)
   },
   methods: {
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.$global.FilterPermissions(
            this.pageData.screen_code
         ).sub_title;
      },
      getData() {
         if (this.$route.params.id) {
            if (this.$global.CheckAction(this.pageData.screen_code, 3)) {
               this.pageData.isEdit = true;
               this.pageData.editIsLoading = true;
               this.pageData.isLoading = true;
               this.$api.GET_METHOD(`product/${this.$route.params.id}`).then((response) => {
                  this.pageData.editIsLoading = false;
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response);

                     this.categories = response.data.categorys;
                     this.sub_categories = response.data.sub_categorys;
                     this.available_statuses = response.data.available_statuses;
                     this.brands = response.data.brands;
                     this.departments = response.data.departments;
                     this.products = response.data.products;
                     this.form = response.data.data;
                     // add every split in if condition to avoid any null error
                     if (this.form.package_products) {
                        this.form.package_products = response.data.data.package_products.split(",").map(e => +e)
                     }
                     if (this.form.categorys) {
                        this.form.categorys = response.data.data.categorys.split(",").map(e => +e)
                     }
                     if (this.form.sub_categorys) {
                        this.form.sub_categorys = response.data.data.sub_categorys.split(",").map(e => +e)
                     }
                     if (this.form.departments) {
                        this.form.departments = response.data.data.departments.split(",").map(e => +e)
                     }
                     // this.$refs.form.validate();

                  }
               })
            } else {
               this.$router.push('/')
            }
         }
         else {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.viewForm = true;
               this.$api.GET_METHOD(`product/create`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     console.log("response", response)
                     this.categories = response.data.categorys;
                     this.sub_categories = response.data.sub_categorys;
                     this.available_statuses = response.data.available_statuses;
                     this.brands = response.data.brands;
                     this.departments = response.data.departments;
                     this.products = response.data.products;
                  }
               })
            } else {
               this.$router.push('/')
            }


         }

      },
      save() {
         if (this.isValid) {
            this.resetHandelSave()
            this.pageData.isLoading = true;
            if (this.pageData.isEdit == true) {
               this.form._method = 'PUT';
               this.$api.POST_METHOD(`product/${this.form.id}`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
                  }
               })
            }
            else {
               this.$api.POST_METHOD(`product`, this.form).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.$router.push(this.pageData.url)
                  }
               })
            }
         }
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`product/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url);
               this.pageData.isLoading = false
            })
         }
      },


   },
   updated() {
      this.isValid;
      this.returnPageData(this.pageData);
   }
};
</script>
<style lang="scss" scoped>
.border_slide {
   border-bottom: 2px solid #bf9d5f69;
}
</style>